import {
    errorAction,
    loadingAction,
    successSideEffectState,
    SideEffectSchema
} from ".";


export const Actions = {
    GET_ACR_SAP_APPROVAL_LIST_DATA: "GET_ACR_SAP_APPROVAL_LIST_DATA",
    GET_ACR_SAP_APPROVAL_LIST_LOADING: " GET_ACR_SAP_APPROVAL_LIST_LOADING",
    GET_ACR_SAP_APPROVAL_LIST_ERROR: " GET_ACR_SAP_APPROVAL_LIST_ERROR",
    GET_ACR_SAP_APPROVAL_LIST_SUCCESS: " GET_ACR_SAP_APPROVAL_LIST_SUCCESS",

    GET_ACR_SAP_EMP_ROW_COUNT_DATA: "GET_ACR_SAP_EMP_ROW_COUNT_DATA",
    GET_ACR_SAP_EMP_ROW_COUNT_LOADING: " GET_ACR_SAP_EMP_ROW_COUNT_LOADING",
    GET_ACR_SAP_EMP_ROW_COUNT_ERROR: " GET_ACR_SAP_EMP_ROW_COUNT_ERROR",
    GET_ACR_SAP_EMP_ROW_COUNT_SUCCESS: " GET_ACR_SAP_EMP_ROW_COUNT_SUCCESS",

    GET_ACR_SAP_REQUEST_INITIATOR_DATA: "GET_ACR_SAP_REQUEST_INITIATOR_DATA",
    GET_ACR_SAP_REQUEST_INITIATOR_LOADING: " GET_ACR_SAP_REQUEST_INITIATOR_LOADING",
    GET_ACR_SAP_REQUEST_INITIATOR_ERROR: " GET_ACR_SAP_REQUEST_INITIATOR_ERROR",
    GET_ACR_SAP_REQUEST_INITIATOR_SUCCESS: " GET_ACR_SAP_REQUEST_INITIATOR_SUCCESS",

    GET_ACR_SAP_REQUEST_DETAILS_DATA: "GET_ACR_SAP_REQUEST_DETAILS_DATA",
    GET_ACR_SAP_REQUEST_DETAILS_LOADING: " GET_ACR_SAP_REQUEST_DETAILS_LOADING",
    GET_ACR_SAP_REQUEST_DETAILS_ERROR: " GET_ACR_SAP_REQUEST_DETAILS_ERROR",
    GET_ACR_SAP_REQUEST_DETAILS_SUCCESS: " GET_ACR_SAP_REQUEST_DETAILS_SUCCESS",

    POST_ACR_SAP_REQUEST_UPDATE_DATA: "POST_ACR_SAP_REQUEST_UPDATE_DATA",
    POST_ACR_SAP_REQUEST_UPDATE_LOADING: " POST_ACR_SAP_REQUEST_UPDATE_LOADING",
    POST_ACR_SAP_REQUEST_UPDATE_ERROR: " POST_ACR_SAP_REQUEST_UPDATE_ERROR",
    POST_ACR_SAP_REQUEST_UPDATE_SUCCESS: " POST_ACR_SAP_REQUEST_UPDATE_SUCCESS",
};
// =======================================================================================================




export interface IGetAcrSapApprovalListItem {
    acr_amount: string;
    acr_no: string;
    approval_status: string;
    approver_1_id: string;
    approver_1_name: string;
    approver_1_status: string;
    approver_2_id: string;
    approver_2_name: string;
    approver_2_status: string;
    asset_category: string;
    entry_date: string;
    requestor_name: string;
    status: string;
    system_id: string;
    wbs_des: string;
    wbs_ele: string;
}


export interface IGetAcrSapApprovalListpayload {
    empId: string;
    start_index: number;
    page_size: number;
    status: string;
    search: string;
}

export interface IGetAcrSapApprovalListData extends SideEffectSchema {
    status_code: string;
    message: string;
    // data: IGetAcrSapApprovalListItem[],
    data: [
        [{ RowCount: number }],
        IGetAcrSapApprovalListItem[]
    ];
}

export const getAcrSapApprovalListAction = (payload: IGetAcrSapApprovalListpayload) => {
    return {
        type: Actions.GET_ACR_SAP_APPROVAL_LIST_DATA,
        payload: payload
    };
};
export const getAcrSapApprovalListLoadingAction = () => loadingAction(Actions.GET_ACR_SAP_APPROVAL_LIST_LOADING);

export const getAcrSapApprovalListErrorAction = (error: string) => errorAction(Actions.GET_ACR_SAP_APPROVAL_LIST_ERROR, error);

export const getAcrSapApprovalListSuccessAction = (data: any) => {
    const payload: IGetAcrSapApprovalListData = {
        ...data,
        ...successSideEffectState
    };

    return {
        type: Actions.GET_ACR_SAP_APPROVAL_LIST_SUCCESS,
        payload: payload
    };
};
// ==============================================================================================================

export interface IGetAcrSapEmpRowCountpayload {
    empId: string
}

export interface IGetAcrSapEmpRowCountData extends SideEffectSchema {
    status_code: string;
    message: string;
    data: {
        approver: boolean;
        initiator: boolean;
    };
}

export const getAcrSapEmpRowCountAction = (payload: IGetAcrSapEmpRowCountpayload) => {
    return {
        type: Actions.GET_ACR_SAP_EMP_ROW_COUNT_DATA,
        payload: payload
    };
};
export const getAcrSapEmpRowCountLoadingAction = () => loadingAction(Actions.GET_ACR_SAP_EMP_ROW_COUNT_LOADING);

export const getAcrSapEmpRowCountErrorAction = (error: string) => errorAction(Actions.GET_ACR_SAP_EMP_ROW_COUNT_ERROR, error);

export const getAcrSapEmpRowCountSuccessAction = (data: any) => {
    const payload: IGetAcrSapEmpRowCountData = {
        // ...{ ...data, ...{ data: { approver: false, initiator: true } } },
        ...data,
        ...successSideEffectState
    };

    return {
        type: Actions.GET_ACR_SAP_EMP_ROW_COUNT_SUCCESS,
        payload: payload
    };
};
// ==============================================================================================================


export interface IGetAcrSapRequestInitiatorItem {
    acr_amount: string;
    acr_no: string;
    approval_status: string;
    approver_1_id: string;
    approver_1_name: string;
    approver_1_status: string;
    approver_2_id: string;
    approver_2_name: string;
    approver_2_status: string;
    asset_category: string;
    entry_date: string;
    requestor_name: string;
    status: string;
    system_id: string;
    wbs_des: string;
    wbs_ele: string;
}


export interface IGetAcrSapRequestInitiatorpayload {
    empId: string;
    start_index: number;
    page_size: number;
    status: string;
    search: string;
}

export interface IGetAcrSapRequestInitiatorData extends SideEffectSchema {
    status_code: string;
    message: string;
    data: [
        [{ RowCount: number }],
        IGetAcrSapRequestInitiatorItem[]
    ];
}

export const getAcrSapRequestInitiatorAction = (payload: IGetAcrSapRequestInitiatorpayload) => {
    return {
        type: Actions.GET_ACR_SAP_REQUEST_INITIATOR_DATA,
        payload: payload
    };
};
export const getAcrSapRequestInitiatorLoadingAction = () => loadingAction(Actions.GET_ACR_SAP_REQUEST_INITIATOR_LOADING);

export const getAcrSapRequestInitiatorErrorAction = (error: string) => errorAction(Actions.GET_ACR_SAP_REQUEST_INITIATOR_ERROR, error);

export const getAcrSapRequestInitiatorSuccessAction = (data: any) => {
    const payload: IGetAcrSapRequestInitiatorData = {
        ...data,
        ...successSideEffectState
    };

    return {
        type: Actions.GET_ACR_SAP_REQUEST_INITIATOR_SUCCESS,
        payload: payload
    };
};
// ==============================================================================================================

export interface IGetAcrSapRequestDetailsItem {
    id: number,
    client_id: string,
    system_id: string,
    company_code: string,
    acr_no: string,
    wbs_ele: string,
    wbs_des: string,
    requestor_name: string,
    status: string,
    entry_date: string,
    entry_time: string,
    project: string,
    auc_no: string,
    network: string,
    project_des: string,
    wbc_dec: string,
    auc_balance: string,
    asset_category: string,
    asset_no: string,
    charge_off_cost_center: string,
    asset_class: string,
    sub_number: string,
    cwip_to_au_pending: string;
    gr_details: {
        "@SEGMENT": string,
        WERKS: number,
        EBELN: string,
        TXZ01: string,
        EBELP: number,
        BEDAT: number,
        EFFWR: string,
        MBLNR: string,
        BUZEI: number,
        BUDAT_MKPF: number,
        BLART: string,
        PO_MENGE: string,
        BUALT: string,
        ZAMTTOBECAP: string,
        ZAMTALRDYCAP: string;
        GJAHR: string;
        PENDING: string;
        PSPID: string,
        POSID: string,
        POSID_DIS: string,
        DOCNUM: string,
        CUT_OVER_IND: string,
        BELNR: string,
    }[],

    asset_master_data: {
        "@SEGMENT": string,
        IMPCODE: number,
        KOSTL: number,
        GSBER: string,
        TXT50: string,
        ASTREF: number,
        LIFNR: string,
        MFGYEAR: number,
        MODELA: string,
        SRLNUM: number,
        DTUSE: number,
        DESCR: string,
        MENGE: string,
        MEINS: string,
        ZLOAD: string,
        DEEMD: string,
        WEIGHT: string,
        SHOPLN: number,
        STORT: string,
        KFZKZ: string,
        RAUMN: string,
        PERNR: number,
        RFID: string,
        FIELD1: string,
        FIELD2: string,
        FIELD3: string
    },

    last_modify_date: string,
    partition_id: string,
    sequence_number: string,
    offset: string,
    acr_type: string,
    notify_list: {
        "@SEGMENT": string,
        PERNR: number,
        BNAME: string,
        NAME_TEXT: string
    }[];

    approver_1_name: string,
    approver_1_id: string,
    approver_1_status: string,
    approver_1_time: string,
    approver_1_remark: string,
    approver_2_name: string,
    approver_2_id: string,
    approver_2_status: string,
    approver_2_time: string,
    approver_2_remark: string,
    final_approve_status: string,
    createdDate: string,
    requestor_id: string
}

export interface IGetAcrSapRequestDetailspayload {
    acr_no: string;
    system_id: string;
}

export interface IGetAcrSapRequestDetailsData extends SideEffectSchema {
    status_code: string;
    message: string;
    data: IGetAcrSapRequestDetailsItem;
}

export const getAcrSapRequestDetailsAction = (payload: IGetAcrSapRequestDetailspayload) => {
    return {
        type: Actions.GET_ACR_SAP_REQUEST_DETAILS_DATA,
        payload: payload
    };
};
export const getAcrSapRequestDetailsLoadingAction = () => loadingAction(Actions.GET_ACR_SAP_REQUEST_DETAILS_LOADING);

export const getAcrSapRequestDetailsErrorAction = (error: string) => errorAction(Actions.GET_ACR_SAP_REQUEST_DETAILS_ERROR, error);

export const getAcrSapRequestDetailsSuccessAction = (data: any) => {
    const payload: IGetAcrSapRequestDetailsData = {
        ...data,
        ...successSideEffectState
    };

    return {
        type: Actions.GET_ACR_SAP_REQUEST_DETAILS_SUCCESS,
        payload: payload
    };
};
// ==============================================================================================================

export interface IPostAcrSapRequestUpdateItem {
}


export interface IPostAcrSapRequestUpdatepayload {
    acr_no: string;
    system_id: string;
    approverType: string;
    approver_status: string;
    remark: string;
    sap_data: {
        CLIENTID: string;
        SYSTEMID: string;
        ACRNUM: string;
        ACRSTATUS: string;
        BNAME: string;
        VALID_DT: string;
        VALID_TM: string;
        VAL_STATUS: string;
        REMARKS: string;
        BNAME2: string;
        APRID_DT2: string;
        APRID_TM2: string;
        APR2_STATUS: string;
        APR2_REMARKS: string;
        FINAL_STATUS: string;
        VALID_REJ_IND: string;
    }[];
}

export interface IPostAcrSapRequestUpdateData extends SideEffectSchema {
    status_code: string;
    message: string;
    data: string;
}

export const postAcrSapRequestUpdateAction = (payload: IPostAcrSapRequestUpdatepayload) => {
    return {
        type: Actions.POST_ACR_SAP_REQUEST_UPDATE_DATA,
        payload: payload
    };
};
export const postAcrSapRequestUpdateLoadingAction = () => loadingAction(Actions.POST_ACR_SAP_REQUEST_UPDATE_LOADING);

export const postAcrSapRequestUpdateErrorAction = (error: string) => errorAction(Actions.POST_ACR_SAP_REQUEST_UPDATE_ERROR, error);

export const postAcrSapRequestUpdateSuccessAction = (data: any) => {
    const payload: IPostAcrSapRequestUpdateData = {
        ...data,
        ...successSideEffectState
    };

    return {
        type: Actions.POST_ACR_SAP_REQUEST_UPDATE_SUCCESS,
        payload: payload
    };
};
