import {
    errorAction,
    loadingAction,
    successSideEffectState,
    SideEffectSchema,
} from ".";

export const Actions = {
    // -------------------------Current Document---------------------------
    GET_CURRENT_MASTER_DATA: "GET_CURRENT_MASTER_DATA",
    GET_CURRENT_MASTER_DATA_LOADING: "GET_CURRENT_MASTER_DATA_LOADING",
    GET_CURRENT_MASTER_DATA_ERROR: "GET_CURRENT_MASTER_DATA_ERROR",
    GET_CURRENT_MASTER_DATA_SUCCESS: "GET_CURRENT_MASTER_DATA_SUCCESS",


    GET_CURRENT_PAYSLIP_WORKSHEET_DATA: "GET_CURRENT_PAYSLIP_WORKSHEET_DATA",
    GET_CURRENT_PAYSLIP_WORKSHEET_DATA_LOADING:
        "GET_CURRENT_PAYSLIP_WORKSHEET_DATA_LOADING",
    GET_CURRENT_PAYSLIP_WORKSHEET_DATA_ERROR: "GET_CURRENT_PAYSLIP_WORKSHEET_DATA_ERROR",
    GET_CURRENT_PAYSLIP_WORKSHEET_DATA_SUCCESS:
        "GET_CURRENT_PAYSLIP_WORKSHEET_DATA_SUCCESS",


    GET_FORM16_DATA: "GET_FORM16_DATA",
    GET_FORM16_DATA_LOADING:
        "GET_FORM16_DATA_LOADING",
    GET_FORM16_DATA_ERROR:
        "GET_FORM16_DATA_ERROR",
    GET_FORM16_DATA_SUCCESS:
        "GET_FORM16_DATA_SUCCESS",

    GET_CURRENT_DOWNLOAD_DATA: "GET_CURRENT_DOWNLOAD_DATA",
    GET_CURRENT_DOWNLOAD_DATA_LOADING:
        "GET_CURRENT_DOWNLOAD_DATA_LOADING",
    GET_CURRENT_DOWNLOAD_DATA_ERROR:
        "GET_CURRENT_DOWNLOAD_DATA_ERROR",
    GET_CURRENT_DOWNLOAD_DATA_SUCCESS:
        "GET_CURRENT_DOWNLOAD_DATA_SUCCESS",

    GET_EMPLOYEE_LATEST_DATA: "GET_EMPLOYEE_LATEST_DATA",
    GET_EMPLOYEE_LATEST_DATA_LOADING:
        "GET_EMPLOYEE_LATEST_DATA_LOADING",
    GET_EMPLOYEE_LATEST_DATA_ERROR:
        "GET_EMPLOYEE_LATEST_DATA_ERROR",
    GET_EMPLOYEE_LATEST_DATA_SUCCESS:
        "GET_EMPLOYEE_LATEST_DATA_SUCCESS",

    GET_PASSBOOK_DETAILS_DATA: "GET_PASSBOOK_DETAILS_DATA",
    GET_PASSBOOK_DETAILS_DATA_LOADING:
        "GET_PASSBOOK_DETAILS_DATA_LOADING",
    GET_PASSBOOK_DETAILS_DATA_ERROR:
        "GET_PASSBOOK_DETAILS_DATA_ERROR",
    GET_PASSBOOK_DETAILS_DATA_SUCCESS:
        "GET_PASSBOOK_DETAILS_DATA_SUCCESS",

    GET_TRUSTFUND_DATA: "GET_TRUSTFUND_DATA",
    GET_TRUSTFUND_DATA_LOADING:
        "GET_TRUSTFUND_DATA_LOADING",
    GET_TRUSTFUND_DATA_ERROR:
        "GET_TRUSTFUND_DATA_ERROR",
    GET_TRUSTFUND_DATA_SUCCESS:
        "GET_TRUSTFUND_DATA_SUCCESS",

    GET_EMPLOYEE_APPLICABLE_DATA: "GET_EMPLOYEE_APPLICABLE_DATA",
    GET_EMPLOYEE_APPLICABLE_DATA_LOADING:
        "GET_EMPLOYEE_APPLICABLE_DATA_LOADING",
    GET_EMPLOYEE_APPLICABLE_DATA_ERROR:
        "GET_EMPLOYEE_APPLICABLE_DATA_ERROR",
    GET_EMPLOYEE_APPLICABLE_DATA_SUCCESS:
        "GET_EMPLOYEE_APPLICABLE_DATA_SUCCESS",

    GET_EMPLOYEE_ADDITIONAL_DATA: "GET_EMPLOYEE_ADDITIONAL_DATA",
    GET_EMPLOYEE_ADDITIONAL_DATA_LOADING:
        "GET_EMPLOYEE_ADDITIONAL_DATA_LOADING",
    GET_EMPLOYEE_ADDITIONAL_DATA_ERROR:
        "GET_EMPLOYEE_ADDITIONAL_DATA_ERROR",
    GET_EMPLOYEE_ADDITIONAL_DATA_SUCCESS:
        "GET_EMPLOYEE_ADDITIONAL_DATA_SUCCESS",

    GET_ADDITIONAL_DOWNLOAD_DATA: "GET_ADDITIONAL_DOWNLOAD_DATA",
    GET_ADDITIONAL_DOWNLOAD_DATA_LOADING: "GET_ADDITIONAL_DOWNLOAD_DATA_LOADING",
    GET_ADDITIONAL_DOWNLOAD_DATA_ERROR: "GET_ADDITIONAL_DOWNLOAD_DATA_ERROR",
    GET_ADDITIONAL_DOWNLOAD_DATA_SUCCESS: "GET_ADDITIONAL_DOWNLOAD_DATA_SUCCESS",


    // -------------------------Previou Document---------------------------
    GET_PREVIOUS_MASTER_DATA: "GET_PREVIOUS_MASTER_DATA",
    GET_PREVIOUS_MASTER_DATA_LOADING: "GET_PREVIOUS_MASTER_DATA_LOADING",
    GET_PREVIOUS_MASTER_DATA_ERROR: "GET_PREVIOUS_MASTER_DATA_ERROR",
    GET_PREVIOUS_MASTER_DATA_SUCCESS: "GET_PREVIOUS_MASTER_DATA_SUCCESS",


    GET_PREVIOUS_PAYSLIP_WORKSHEET_DATA: "GET_PREVIOUS_PAYSLIP_WORKSHEET_DATA",
    GET_PREVIOUS_PAYSLIP_WORKSHEET_DATA_LOADING:
        "GET_PREVIOUS_PAYSLIP_WORKSHEET_DATA_LOADING",
    GET_PREVIOUS_PAYSLIP_WORKSHEET_DATA_ERROR: "GET_PREVIOUS_PAYSLIP_WORKSHEET_DATA_ERROR",
    GET_PREVIOUS_PAYSLIP_WORKSHEET_DATA_SUCCESS:
        "GET_PREVIOUS_PAYSLIP_WORKSHEET_DATA_SUCCESS",


    GET_ALL_OLD_DOC_DATA: "GET_ALL_OLD_DOC_DATA",
    GET_ALL_OLD_DOC_DATA_LOADING:
        "GET_ALL_OLD_DOC_DATA_LOADING",
    GET_ALL_OLD_DOC_DATA_ERROR:
        "GET_ALL_OLD_DOC_DATA_ERROR",
    GET_ALL_OLD_DOC_DATA_SUCCESS:
        "GET_ALL_OLD_DOC_DATA_SUCCESS",


    GET_PREVIOUS_DOWNLOAD_DATA: "GET_PREVIOUS_DOWNLOAD_DATA",
    GET_PREVIOUS_DOWNLOAD_DATA_LOADING:
        "GET_PREVIOUS_DOWNLOAD_DATA_LOADING",
    GET_PREVIOUS_DOWNLOAD_DATA_ERROR:
        "GET_PREVIOUS_DOWNLOAD_DATA_ERROR",
    GET_PREVIOUS_DOWNLOAD_DATA_SUCCESS:
        "GET_PREVIOUS_DOWNLOAD_DATA_SUCCESS",

    GET_GENERATE_BONAFIDE_DATA: "GET_GENERATE_BONAFIDE_DATA",
    GET_GENERATE_BONAFIDE_DATA_LOADING: "GET_GENERATE_BONAFIDE_DATA_LOADING",
    GET_GENERATE_BONAFIDE_DATA_ERROR: "GET_GENERATE_BONAFIDE_DATA_ERROR",
    GET_GENERATE_BONAFIDE_DATA_SUCCESS: "GET_GENERATE_BONAFIDE_DATA_SUCCESS",

};
//   ---------------------------------------------------------------------------------------------

export interface ICurrentMasterDataPayLoad {
    doj: string;
    emp_id: string;
}

export interface IPayslipMonth {
    month: string;
    month_count: string;
}
export interface ICurrentMasterData extends SideEffectSchema {
    status_code: string;
    message: string;
    data: {
        payslip_months: IPayslipMonth[];
        payslip_years: string[];
        assesment_years: string[];
        financial_years: {
            fy_year: string;
            value: number;
        }[];
    }
}
export const getCurrentMasterDataAction = (
    access_token: string,
    emp_Code: string,
    payload: ICurrentMasterDataPayLoad
) => {
    return {
        type: Actions.GET_CURRENT_MASTER_DATA,
        payload: { access_token, emp_Code, payload },
    };
};
export const getCurrentMasterDataLoadingAction = () =>
    loadingAction(Actions.GET_CURRENT_MASTER_DATA_LOADING);
export const getCurrentMasterDataErrorAction = (error: string) =>
    errorAction(Actions.GET_CURRENT_MASTER_DATA_ERROR, error);
export const getCurrentMasterDataSuccessAction = (data: any) => {
    const payload: ICurrentMasterData = {
        ...data,
        ...successSideEffectState,
    };
    return {
        type: Actions.GET_CURRENT_MASTER_DATA_SUCCESS,
        payload: payload,
    };
};
// ---------------------------------------------------------------------------------------------

export interface ICurrentPayslipWorksheetPayLaod {
    emp_id: string;
    month_number?: string;
    year?: string;

}
export interface ICurrentPayslipWorksheet {
    month_number: string;
    month_name: string;
    year: number;
    company: string;
    path: string;
}[];

export interface IItSheetMonthList {
    month_number: string;
    month_name: string;
    year: number;
    company: string;
    path: string;
}[];

export interface ICurrentPayslipWorksheetData extends SideEffectSchema {
    status_code: string;
    message: string;
    data: {
        payslip_month_list: ICurrentPayslipWorksheet[];
        it_sheet_month_list: IItSheetMonthList[];
    };
}
export const getCurrentPayslipWorksheetDataAction = (
    access_token: string,
    emp_Code: string,
    payload: ICurrentPayslipWorksheetPayLaod
) => {
    return {
        type: Actions.GET_CURRENT_PAYSLIP_WORKSHEET_DATA,
        payload: { access_token, emp_Code, payload },
    };
};
export const getCurrentPayslipWorksheetDataLoadingAction = () =>
    loadingAction(Actions.GET_CURRENT_PAYSLIP_WORKSHEET_DATA_LOADING);
export const getCurrentPayslipWorksheetDataErrorAction = (error: string) =>
    errorAction(Actions.GET_CURRENT_PAYSLIP_WORKSHEET_DATA_ERROR, error);
export const getCurrentPayslipWorksheetDataSuccessAction = (data: any) => {
    const payload: ICurrentPayslipWorksheetData = {
        ...data,
        ...successSideEffectState,
    };
    return {
        type: Actions.GET_CURRENT_PAYSLIP_WORKSHEET_DATA_SUCCESS,
        payload: payload,
    };
};
// ---------------------------------------------------------------------------------------------

export interface IFormSixteenDataPayLoad {
    emp_id: string;
    assesment_year: string;
}

export interface IFormSixteen {
    type: string;
    financial_year: string;
    company: string;
    path: string;
}
export interface IFormSixteenData extends SideEffectSchema {
    status_code: string;
    message: string;
    data: { form16_list: IFormSixteen[]; };
}
export const getFormSixteenDataAction = (
    access_token: string,
    emp_Code: string,
    payload: IFormSixteenDataPayLoad
) => {
    return {
        type: Actions.GET_FORM16_DATA,
        payload: { access_token, emp_Code, payload },
    };
};
export const getFormSixteenDataLoadingAction = () =>
    loadingAction(Actions.GET_FORM16_DATA_LOADING);
export const getFormSixteenDataErrorAction = (error: string) =>
    errorAction(Actions.GET_FORM16_DATA_ERROR, error);
export const getFormSixteenDataSuccessAction = (data: any) => {
    const payload: IFormSixteenData = {
        ...data,
        ...successSideEffectState,
    };
    return {
        type: Actions.GET_FORM16_DATA_SUCCESS,
        payload: payload,
    };
};
// ---------------------------------------------------------------------------------------------

export interface ICurrentDownloadDocumentDataPayLoad {
    path: string;
}


export interface ICurrentDownloadDocumentData extends SideEffectSchema {
    status_code: string;
    message: string;
    data: {
        url: string
    }
}
export const getCurrentDownloadDocumentDataAction = (
    access_token: string,
    emp_Code: string,
    payload: ICurrentDownloadDocumentDataPayLoad
) => {
    return {
        type: Actions.GET_CURRENT_DOWNLOAD_DATA,
        payload: { access_token, emp_Code, payload },
    };
};
export const getCurrentDownloadDocumentDataLoadingAction = () =>
    loadingAction(Actions.GET_CURRENT_DOWNLOAD_DATA_LOADING);
export const getCurrentDownloadDocumentDataErrorAction = (error: string) =>
    errorAction(Actions.GET_CURRENT_DOWNLOAD_DATA_ERROR, error);
export const getCurrentDownloadDocumentDataSuccessAction = (data: any) => {
    const payload: ICurrentDownloadDocumentData = {
        ...data,
        ...successSideEffectState,
    };
    return {
        type: Actions.GET_CURRENT_DOWNLOAD_DATA_SUCCESS,
        payload: payload,
    };
};

// ---------------------------------------------------------------------------------------------
export interface IPreviousMasterDataPayLoad {
    emp_id: string;
}

export interface IPreviousPayslipList {
    month: string;
    month_count: string;
}[];
export interface IPreviousMasterData extends SideEffectSchema {
    status_code: string;
    message: string;
    payslip_months: IPreviousPayslipList[];
    assesment_years: string[];
}
export const getPreviousMasterDataAction = (
    access_token: string,
    emp_Code: string,
    payload?: IPreviousMasterDataPayLoad,

) => {
    return {
        type: Actions.GET_PREVIOUS_MASTER_DATA,
        payload: { access_token, emp_Code, payload },
    };
};
export const getPreviousMasterDataLoadingAction = () =>
    loadingAction(Actions.GET_PREVIOUS_MASTER_DATA_LOADING);
export const getPreviousMasterDataErrorAction = (error: string) =>
    errorAction(Actions.GET_PREVIOUS_MASTER_DATA_ERROR, error);
export const getPreviousMasterDataSuccessAction = (data: any) => {
    const payload: IPreviousMasterData = {
        ...data,
        ...successSideEffectState,
    };
    return {
        type: Actions.GET_PREVIOUS_MASTER_DATA_SUCCESS,
        payload: payload,
    };
};
// ---------------------------------------------------------------------------------------------

export interface IPreviousPayslipDataPayLoad {
    emp_id: string;
    document_type: string;
    month_number: string;
    year?: string
}
export interface IPreviousPayslip {
    month_number: string;
    month_name: string;
    year: number;
    company: string;
}
export interface IPreviousPayslipData extends SideEffectSchema {
    status_code: string;
    message: string;
    payslip_month_list: IPreviousPayslip[];
}
export const getPreviousPayslipDataAction = (
    access_token: string,
    emp_Code: string,
    payload: IPreviousPayslipDataPayLoad
) => {
    return {
        type: Actions.GET_PREVIOUS_PAYSLIP_WORKSHEET_DATA,
        payload: { access_token, emp_Code, payload },
    };
};
export const getPreviousPayslipDataLoadingAction = () =>
    loadingAction(Actions.GET_PREVIOUS_PAYSLIP_WORKSHEET_DATA_LOADING);
export const getPreviousPayslipDataErrorAction = (error: string) =>
    errorAction(Actions.GET_PREVIOUS_PAYSLIP_WORKSHEET_DATA_ERROR, error);
export const getPreviousPayslipDataSuccessAction = (data: any) => {
    const payload: IPreviousPayslipData = {
        ...data,
        ...successSideEffectState,
    };
    return {
        type: Actions.GET_PREVIOUS_PAYSLIP_WORKSHEET_DATA_SUCCESS,
        payload: payload,
    };
};

//------------------------------------------------------------------------------------------------
export interface IOldDocumentDataPayLoad {
    emp_id: string;
    assesment_year: string;
}

export interface IData {
    type: string;
    financial_year: string;
    company: string;
    path: string;
}
export interface IOldDocumentData extends SideEffectSchema {
    status_code: string;
    message: string;
    data: { form16_list: IData[]; };
}
export const getOldDocumentDataAction = (
    access_token: string,
    emp_Code: string,
    payload: IOldDocumentDataPayLoad
) => {
    return {
        type: Actions.GET_ALL_OLD_DOC_DATA,
        payload: { access_token, emp_Code, payload },
    };
};
export const getOldDocumentDataLoadingAction = () =>
    loadingAction(Actions.GET_ALL_OLD_DOC_DATA_LOADING);
export const getOldDocumentDataErrorAction = (error: string) =>
    errorAction(Actions.GET_ALL_OLD_DOC_DATA_ERROR, error);
export const getOldDocumentDataSuccessAction = (data: any) => {
    const payload: IOldDocumentData = {
        ...data,
        ...successSideEffectState,
    };
    return {
        type: Actions.GET_ALL_OLD_DOC_DATA_SUCCESS,
        payload: payload,
    };
};

//------------------------------------------------------
export interface IPreviousDownloadDocumentDataPayLoad {
    emp_id: string;
    month: string;
    year: number;
    document: string;
    document_type: string;
    financial_year?: string;
    company: string;
}


export interface IPreviousDownloadDocumentData extends SideEffectSchema {
    status_code: string;
    message: string;
}
export const getPreviousDownloadDocumentDataAction = (
    access_token: string,
    emp_Code: string,
    payload: IPreviousDownloadDocumentDataPayLoad
) => {
    return {
        type: Actions.GET_PREVIOUS_DOWNLOAD_DATA,
        payload: { access_token, emp_Code, payload },
    };
};
export const getPreviousDownloadDocumentDataLoadingAction = () =>
    loadingAction(Actions.GET_PREVIOUS_DOWNLOAD_DATA_LOADING);
export const getPreviousDownloadDocumentDataErrorAction = (error: string) =>
    errorAction(Actions.GET_PREVIOUS_DOWNLOAD_DATA_ERROR, error);
export const getPreviousDownloadDocumentDataSuccessAction = (data: any) => {
    const payload: IPreviousDownloadDocumentData = {
        ...data,
        ...successSideEffectState,
    };
    return {
        type: Actions.GET_PREVIOUS_DOWNLOAD_DATA_SUCCESS,
        payload: payload,
    };
};

//-----------------------------------------------------------------------------------------


export interface IEmployeeLatestDataPayLoad {
    emp_id: string;
}
export interface IPayslipList {
    month_number: string;
    month_name: string;
    year: number;
    company: string;
    path: string;

}
export interface IItSheetList {
    month_number: string;
    month_name: string;
    year: number;
    company: string;
    path: string;

}

export interface IEmployeeLatestData extends SideEffectSchema {
    status_code: string;
    message: string;
    data: { payslip_month_list: IPayslipList[], it_sheet_month_list: IItSheetList[]; };
}
export const getEmployeeLatestDataAction = (
    access_token: string,
    emp_Code: string,
    payload: IEmployeeLatestDataPayLoad
) => {
    return {
        type: Actions.GET_EMPLOYEE_LATEST_DATA,
        payload: { access_token, emp_Code, payload },
    };
};
export const getEmployeeLatestDataLoadingAction = () =>
    loadingAction(Actions.GET_EMPLOYEE_LATEST_DATA_LOADING);
export const getEmployeeLatestDataErrorAction = (error: string) =>
    errorAction(Actions.GET_EMPLOYEE_LATEST_DATA_ERROR, error);
export const getEmployeeLatestDataSuccessAction = (data: any) => {
    const payload: IEmployeeLatestData = {
        ...data,
        ...successSideEffectState,
    };
    return {
        type: Actions.GET_EMPLOYEE_LATEST_DATA_SUCCESS,
        payload: payload,
    };
};
//-------------------------------------------------------------------------------------------------------

export interface IPassbookDetailDataPayLoad {
    emp_id: string;

}


export interface IPassbookDetailData extends SideEffectSchema {
    status_code: string;
    message: string;
    passbook_details: string;
}
export const getPassbookDetailDataAction = (
    access_token: string,
    emp_Code: string,
    payload: IPassbookDetailDataPayLoad
) => {
    return {
        type: Actions.GET_PASSBOOK_DETAILS_DATA,
        payload: { access_token, emp_Code, payload },
    };
};
export const getPassbookDetailDataLoadingAction = () =>
    loadingAction(Actions.GET_PASSBOOK_DETAILS_DATA_LOADING);
export const getPassbookDetailDataErrorAction = (error: string) =>
    errorAction(Actions.GET_PASSBOOK_DETAILS_DATA_ERROR, error);
export const getPassbookDetailDataSuccessAction = (data: any) => {
    const payload: IPassbookDetailData = {
        ...data,
        ...successSideEffectState,
    };
    return {
        type: Actions.GET_PASSBOOK_DETAILS_DATA_SUCCESS,
        payload: payload,
    };
};

//--------------------------------------------------------------------------------------------------

export interface IDownloadTrustFundDataPayLoad {
    emp_id: string;
    fin_year: string;
    annual_type: string;
}

export interface IDownloadTrustFundData extends SideEffectSchema {
    status_code: string;
    message: string;
    passbook_details: string;
}
export const getDownloadTrustFundDataAction = (
    access_token: string,
    emp_Code: string,
    payload: IDownloadTrustFundDataPayLoad
) => {
    return {
        type: Actions.GET_TRUSTFUND_DATA,
        payload: { access_token, emp_Code, payload },
    };
};
export const getDownloadTrustFundDataLoadingAction = () =>
    loadingAction(Actions.GET_TRUSTFUND_DATA_LOADING);
export const getDownloadTrustFundDataErrorAction = (error: string) =>
    errorAction(Actions.GET_TRUSTFUND_DATA_ERROR, error);
export const getDownloadTrustFundDataSuccessAction = (data: any) => {
    const payload: IDownloadTrustFundData = {
        ...data,
        ...successSideEffectState,
    };
    return {
        type: Actions.GET_TRUSTFUND_DATA_SUCCESS,
        payload: payload,
    };
};

//----------------------------------------------------------------------------------------------------------

export interface IEmployeeApplicableDataPayLoad {
    emp_id: string;
    finanical_year: number;

}

export interface IEmployeeApplicableData extends SideEffectSchema {
    status_code: string;
    message: string;
    applicable_for_pension_scheme: boolean;
    applicable_for_provident_fund: boolean;
    applicable_for_superannuation_scheme: boolean;
}
export const getEmployeeApplicableDataAction = (
    access_token: string,
    emp_Code: string,
    payload: IEmployeeApplicableDataPayLoad
) => {
    return {
        type: Actions.GET_EMPLOYEE_APPLICABLE_DATA,
        payload: { access_token, emp_Code, payload },
    };
};
export const getEmployeeApplicableDataLoadingAction = () =>
    loadingAction(Actions.GET_EMPLOYEE_APPLICABLE_DATA_LOADING);
export const getEmployeeApplicableDataErrorAction = (error: string) =>
    errorAction(Actions.GET_EMPLOYEE_APPLICABLE_DATA_ERROR, error);
export const getEmployeeApplicableDataSuccessAction = (data: any) => {
    const payload: IEmployeeApplicableData = {
        ...data,
        ...successSideEffectState,
    };
    return {
        type: Actions.GET_EMPLOYEE_APPLICABLE_DATA_SUCCESS,
        payload: payload,
    };
};


//----------------------------------------------------------------------------------------------------------

export interface IEmployeeAdditionalDataPayLoad {
    emp_id: string;
}

export interface IEmployeeAdditionalData extends SideEffectSchema {
    status_code: string;
    message: string;
    data: {
        additional_documents_list: {
            title: string;
            description: string;
            document_type: string;
            file_name: string
        }[];
    }
}
export const getEmployeeAdditionalDataAction = (
    access_token: string,
    emp_Code: string,
    payload: IEmployeeAdditionalDataPayLoad
) => {
    return {
        type: Actions.GET_EMPLOYEE_ADDITIONAL_DATA,
        payload: { access_token, emp_Code, payload },
    };
};
export const getEmployeeAdditionalDataLoadingAction = () =>
    loadingAction(Actions.GET_EMPLOYEE_ADDITIONAL_DATA_LOADING);
export const getEmployeeAdditionalDataErrorAction = (error: string) =>
    errorAction(Actions.GET_EMPLOYEE_ADDITIONAL_DATA_ERROR, error);
export const getEmployeeAdditionalDataSuccessAction = (data: any) => {
    const payload: IEmployeeAdditionalData = {
        ...data,
        ...successSideEffectState,
    };
    return {
        type: Actions.GET_EMPLOYEE_ADDITIONAL_DATA_SUCCESS,
        payload: payload,
    };
};


export interface IAdditionalDownloadDocumentDataPayLoad {
    emp_id: string;
    document_type: string;
    file_name: string;
}


export interface IAdditionalDownloadDocumentData extends SideEffectSchema {
    status_code: string;
    message: string;
    data: {
        url: string
    }
}
export const getAdditionalDownloadDocumentDataAction = (
    access_token: string,
    emp_Code: string,
    payload: IAdditionalDownloadDocumentDataPayLoad
) => {
    return {
        type: Actions.GET_ADDITIONAL_DOWNLOAD_DATA,
        payload: { access_token, emp_Code, payload },
    };
};
export const getAdditionalDownloadDocumentDataLoadingAction = () =>
    loadingAction(Actions.GET_ADDITIONAL_DOWNLOAD_DATA_LOADING);
export const getAdditionalDownloadDocumentDataErrorAction = (error: string) =>
    errorAction(Actions.GET_ADDITIONAL_DOWNLOAD_DATA_ERROR, error);
export const getAdditionalDownloadDocumentDataSuccessAction = (data: any) => {
    const payload: IAdditionalDownloadDocumentData = {
        ...data,
        ...successSideEffectState,
    };
    return {
        type: Actions.GET_ADDITIONAL_DOWNLOAD_DATA_SUCCESS,
        payload: payload,
    };
};


export interface IGenerateBonafideDataPayLoad {
    emp_id: string;
    template_code: string;
    concern_authorities: string;
    reason: string;
    joining_date: boolean;
    current_address: boolean;
    permanent_address: boolean;
}

export interface IGenerateBonafideData extends SideEffectSchema {
    status_code: string;
    message: string;
    bonafide_details: string;
}
export const getGenerateBonafideDataAction = (
    access_token: string,
    emp_Code: string,
    payload: IGenerateBonafideDataPayLoad
) => {
    return {
        type: Actions.GET_GENERATE_BONAFIDE_DATA,
        payload: { access_token, emp_Code, payload },
    };
};
export const getGenerateBonafideDataLoadingAction = () =>
    loadingAction(Actions.GET_GENERATE_BONAFIDE_DATA_LOADING);
export const getGenerateBonafideDataErrorAction = (error: string) =>
    errorAction(Actions.GET_GENERATE_BONAFIDE_DATA_ERROR, error);
export const getGenerateBonafideDataSuccessAction = (data: any) => {
    const payload: IGenerateBonafideData = {
        ...data,
        ...successSideEffectState,
    };
    return {
        type: Actions.GET_GENERATE_BONAFIDE_DATA_SUCCESS,
        payload: payload,
    };
};