import React from "react";
import { Menu, Segment, Flex, Text, Divider, Box, Table, Input, Button, Grid, Pill, Dialog, CloseIcon, TextArea, ArrowLeftIcon, CalendarIcon, Skeleton, Loader } from "@fluentui/react-northstar";
import { IState } from "../../App";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { RootSchema } from "../../redux/reducer";
import { IAcrRequestDetails } from "../../container/ACRApprovals";
import "./acrRequestDetails.scss";
import * as AcrSapActions from "../../redux/actions/AcrSapActions";
import ErrorComponent from "../ErrorComponent";
import DataNotFound from "../DNF";
import { ErrorToast, SuccessToast } from "../ToastifyComponent";
import moment from "moment";
import { TraceEvent, getAddTwoDecimalNumber, getCommaSeparatorNumber, getDDMMYYYFormat, getIntegerNoToString, getValueZeroToConversion } from "../../constant";
import { getAppInsights } from "../../telemetry/telemetryService";

interface IisAction {
    isActive?: boolean;
    action?: string;
    remarks?: string
    isApproverActive?: boolean
}

const items = [{
    key: "1",
    content: <Flex gap="gap.small" vAlign='center' hAlign='center' styles={{ padding: "0.4rem 0", fontSize: "16px" }}><Text content="GR Details" weight="semibold" /></Flex>,
    value: "1"
},
{
    key: "2",
    content: <Flex gap="gap.small" vAlign='center' hAlign='center' styles={{ padding: "0.4rem 0", fontSize: "16px" }}><Text content="Asset Master Data" weight="semibold" /></Flex>,
    value: "2",
}];

interface AssetApprovalProps {
    QueryData: IState;
    acrDetails?: IAcrRequestDetails;
    setAcrDetails?: React.Dispatch<React.SetStateAction<IAcrRequestDetails | undefined>>;
    initiator?: boolean
}
const ACRRequestDetails: React.FC<AssetApprovalProps> = ({ QueryData, acrDetails, setAcrDetails, initiator }) => {

    const [activeMenuItem, setActiveMenuItem] = React.useState("0");
    const [isAction, setIsAction] = React.useState<IisAction>();
    const [appInsightData, setAppInsightData] = React.useState<AcrSapActions.IPostAcrSapRequestUpdatepayload>();

    const { appInsights, trackEvent } = getAppInsights();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userId = getIntegerNoToString(QueryData.employeeId as string);
    const state = useSelector((state: RootSchema) => state);
    const acrReqDetails = state.acrSapData.getAcrSapRequestDetailsData;
    const approvalStatus = ["Approved", "Rejected"];

    const { acrNo, sysId } = useParams();

    const headerValues = [
        {
            valueKey: "project",
            key: "Project",
            title: "Project",
        },
        {
            valueKey: "project_des",
            key: "Project Description",
            title: "Project Description",
            type: "textarea"
        },
        {
            valueKey: "requestor_name",
            key: "Requestor Name",
            title: "Requestor Name",
        },
        {
            valueKey: "wbs_ele",
            key: "WBS Elements",
            title: "WBS Elements",
        },
        {
            valueKey: "wbs_des",
            key: "WBS Description",
            title: "WBS Description",
            type: "textarea"
        },
        {
            valueKey: "entry_date",
            key: "Entry Date",
            title: "Entry Date",
        },
        {
            valueKey: "auc_no",
            key: "AUC Number",
            title: "AUC Number",
        },
        {
            valueKey: "cwip_to_au_pending",
            key: "CWIP to AUC Pending",
            title: "CWIP to AUC Pending",
            comma: true
        },
        {
            valueKey: "entry_time",
            key: "Entry Time",
            title: "Entry Time",
        },
        {
            valueKey: "acr_amount",
            key: "ACR Amount",
            title: "ACR Amount",
            comma: true
        },
        {
            valueKey: "network",
            key: "Network",
            title: "Network",
        },
        {
            valueKey: "auc_balance",
            key: "AUC Balance",
            title: "AUC Balance",
            comma: true
        },
    ];

    const grDetailsData = [
        {
            valueKey: "asset_category",
            key: "Asset Category",
            title: "Asset Category",
        },
        {
            valueKey: "asset_class",
            key: "Asset Class",
            title: "Asset Class",
        },
        {
            valueKey: "asset_no",
            key: "Parent/Asset No",
            title: "Parent/Asset No",
        },
        {
            valueKey: "sub_number",
            key: "Sub Number",
            title: "Sub Number",
        },
        {
            valueKey: "charge_off_cost_center",
            key: "Charge Off - Cost Center",
            title: "Charge Off - Cost Center",
        },
    ];

    const assetMasterData = [
        {
            valueKey: "IMPCODE",
            key: "Import/Indigenous Code",
            title: "Import/Indigenous Code",
        },
        {
            valueKey: "MENGE",
            key: "Quantity",
            title: "Quantity",
        },
        {
            valueKey: "DEEMD",
            key: "Deemed Verified/NC/FX/M",
            title: "Deemed Verified/NC/FX/M",
        },
        {
            valueKey: "KOSTL",
            key: "Cost Center",
            title: "Cost Center",
        },
        {
            valueKey: "ZLOAD",
            key: "Load in KW",
            title: "Load in KW",
        },
        {
            valueKey: "SHOPLN",
            key: "Shop Line No.",
            title: "Shop Line No.",
        },
        {
            valueKey: "GSBER",
            key: "Business Area",
            title: "Business Area",
        },
        {
            valueKey: "WEIGHT",
            key: "Weight of Equipment (Kg)",
            title: "Weight of Equipment (Kg)",
        },
        {
            valueKey: "KFZKZ",
            key: "License Plate",
            title: "License Plate",
        },
        {
            valueKey: "TXT50",
            key: "Asset Description",
            title: "Asset Description",
            type: "textarea"
        },
        {
            valueKey: "STORT",
            key: "Location",
            title: "Location",
        },
        {
            valueKey: "MODELA",
            key: "Model No.",
            title: "Model No.",
        },
        {
            valueKey: "ASTREF",
            key: "Galaxy Number",
            title: "Galaxy Number",
        },
        {
            valueKey: "RAUMN",
            key: "Room",
            title: "Room",
        },
        {
            valueKey: "SRLNUM",
            key: "Serial Number",
            title: "Serial Number",
        },
        {
            valueKey: "LIFNR",
            key: "Vendor Code",
            title: "Vendor Code",
        },
        {
            valueKey: "MFGYEAR",
            key: "Year Of Mfg",
            title: "Year Of Mfg",
        },
        {
            valueKey: "RFID",
            key: "RFID Tag No",
            title: "RFID Tag No",
        },
        {
            valueKey: "DTUSE",
            key: "Capitalization Date",
            title: "Capitalization Date",
        },

        {
            valueKey: "DESCR",
            key: "Notes for Capitalization",
            title: "Notes for Capitalization",
            type: "textarea"
        },
        {
            valueKey: "PERNR",
            key: "Personnel Number",
            title: "Personnel Number",
        },
        {
            valueKey: "FIELD1",
            key: "User Field 1",
            title: "User Field 1",
        },
        {
            valueKey: "FIELD2",
            key: "User Field 2",
            title: "User Field 2",
        },
        {
            valueKey: "FIELD3",
            key: "User Field 3",
            title: "User Field 3",
        },
    ];

    const dataHeader = [
        {
            key: "h1.1",
            content: <Text size="medium" weight="bold" content="Project" />,
            styles: { minWidth: "90px", maxWidth: "90px" },
        },
        {
            key: "h2.1",
            content: <Text size="medium" weight="bold" content="WBS Element" />,
            // styles: { minWidth: "150px", maxWidth: "150px" },
            className: "wbs-ele"
        },
        {
            key: "h3",
            content: <Text size="medium" weight="bold" content="WBS Description" />,
            styles: { minWidth: "180px" },
        },
        {
            key: "h4",
            content: <Text size="medium" weight="bold" content="Plant" />,
            styles: { minWidth: "70px", maxWidth: "70px" },
        },
        {
            key: "h5",
            content: <Text size="medium" weight="bold" content="Item" />,
            styles: { minWidth: "70px", maxWidth: "70px" },
        },
        {
            key: "h6",
            content: <Text size="medium" weight="bold" content="PO Number" />,
            styles: { minWidth: "100px", maxWidth: "100px" },
        },
        {
            key: "h7",
            content: <Text size="medium" weight="bold" content="PO Description" />,
            styles: { minWidth: "170px", maxWidth: "170px" },
        },
        {
            key: "h8",
            content: <Text size="medium" weight="bold" content="PO Date" />,
            styles: { minWidth: "90px", maxWidth: "90px" },
        },
        {
            key: "h9",
            content: <Text size="medium" weight="bold" content="PO Value" />,
            styles: { justifyContent: "end", minWidth: "90px", maxWidth: "90px" },
        },
        {
            key: "h10",
            content: <Text size="medium" weight="bold" content="Line item" />,
            styles: { minWidth: "80px", maxWidth: "80px" },
        },
        {
            key: "h11",
            content: <Text size="medium" weight="bold" content="Material Doc. Number" />,
            styles: { minWidth: "160px", maxWidth: "160px" },
        },
        {
            key: "h12",
            content: <Text size="medium" weight="bold" content="FI Doc. Number" />,
            styles: { minWidth: "140px", maxWidth: "140px" },
        },
        {
            key: "h13",
            content: <Text size="medium" weight="bold" content="Material Doc. Date" />,
            styles: { minWidth: "140px", maxWidth: "140px" },
        },
        {
            key: "h14",
            content: <Text size="medium" weight="bold" content="Fiscal Year" />,
            styles: { minWidth: "100px", maxWidth: "100px" },
        },
        {
            key: "h15",
            content: <Text size="medium" weight="bold" content="Document Type" />,
            styles: { minWidth: "130px", maxWidth: "130px" },
        },
        {
            key: "h16",
            content: <Text size="medium" weight="bold" content="GR Quantity" />,
            styles: { minWidth: "130px", maxWidth: "130px" },
        },
        {
            key: "h17",
            content: <Text size="medium" weight="bold" content="GR Amount" />,
            styles: { justifyContent: "end", minWidth: "100px", maxWidth: "100px" },
        },
        {
            key: "h18",
            content: <Text size="medium" weight="bold" content="Pending" />,
            styles: { justifyContent: "end", minWidth: "100px", maxWidth: "100px" },
        },
        {
            key: "h19",
            content: <Text size="medium" weight="bold" content="Already capitalized" />,
            styles: { justifyContent: "end", minWidth: "160px", maxWidth: "160px" },
        },
        {
            key: "h20",
            content: <Text size="medium" weight="bold" content="Amount To Be Capitalize" />,
            styles: { justifyContent: "end", minWidth: "180px", maxWidth: "180px" },
        },
        {
            key: "h21",
            content: <Text size="medium" weight="bold" content="Cutover Ind" />,
            styles: { minWidth: "110px", maxWidth: "110px" },
        },
    ];

    const rows = state.acrSapData.getAcrSapRequestDetailsData.isSuccess && state.acrSapData.getAcrSapRequestDetailsData.status_code === "200" ?
        Array.isArray(state.acrSapData.getAcrSapRequestDetailsData.data.gr_details) ? state.acrSapData.getAcrSapRequestDetailsData.data.gr_details : [state.acrSapData.getAcrSapRequestDetailsData.data.gr_details]
        : [];

    const dataRows = rows.map((item, index) => {
        return ({
            key: index + ".1",
            items: [
                {
                    key: index + "-1",
                    content: state.acrSapData.getAcrSapRequestDetailsData.data.project,
                    styles: { minWidth: "90px", maxWidth: "90px", }
                },
                {
                    key: index + "-2",
                    content: state.acrSapData.getAcrSapRequestDetailsData.data.wbs_ele,
                    // styles: { minWidth: "150px", maxWidth: "150px" }
                    className: "wbs-ele"
                },
                {
                    key: index + "-3",
                    content: state.acrSapData.getAcrSapRequestDetailsData.data.wbs_des,
                    styles: { minWidth: "180px" }
                },
                {
                    key: index + "-4",
                    content: item.WERKS,
                    styles: { minWidth: "70px", maxWidth: "70px" }
                },
                {
                    key: index + "-5",
                    content: item.EBELP,
                    styles: { minWidth: "70px", maxWidth: "70px" },
                },
                {
                    key: index + "-6",
                    content: item.EBELN,
                    styles: { minWidth: "100px", maxWidth: "100px" },
                },
                {
                    key: index + "-7",
                    content: item.TXZ01,
                    styles: { minWidth: "170px", maxWidth: "170px" },
                },
                {
                    key: index + "-8",
                    content: getDDMMYYYFormat(item.BEDAT.toString()),
                    styles: { minWidth: "90px", maxWidth: "90px" },
                },
                {
                    key: index + "-9",
                    content: getCommaSeparatorNumber(item.EFFWR),
                    styles: { justifyContent: "end", minWidth: "90px", maxWidth: "90px", },
                },
                {
                    key: index + "-10",
                    content: item.BUZEI,
                    styles: { minWidth: "80px", maxWidth: "80px" },
                },
                {
                    key: index + "-11",
                    content: item.MBLNR,
                    styles: { minWidth: "160px", maxWidth: "160px" },
                },
                {
                    key: index + "-12",
                    content: item.BELNR,
                    styles: { minWidth: "140px", maxWidth: "140px" },
                },
                {
                    key: index + "-13",
                    content: getDDMMYYYFormat(item.BUDAT_MKPF.toString()),
                    styles: { minWidth: "140px", maxWidth: "140px" },
                },
                {
                    key: index + "-14",
                    content: item.GJAHR,
                    styles: { minWidth: "100px", maxWidth: "100px" },
                },
                {
                    key: index + "-15",
                    content: item.BLART,
                    styles: { minWidth: "130px", maxWidth: "130px" },
                },
                {
                    key: index + "-16",
                    content: getAddTwoDecimalNumber(item.PO_MENGE),
                    styles: { minWidth: "130px", maxWidth: "130px" },
                },
                {
                    key: index + "-17",
                    content: getCommaSeparatorNumber(item.BUALT),
                    styles: { justifyContent: "end", minWidth: "100px", maxWidth: "100px" },
                },
                {
                    key: index + "-18",
                    content: getCommaSeparatorNumber(item.PENDING),
                    styles: { justifyContent: "end", minWidth: "100px", maxWidth: "100px" },
                },
                {
                    key: index + "-19",
                    content: getCommaSeparatorNumber(item.ZAMTALRDYCAP),
                    styles: { justifyContent: "end", minWidth: "160px", maxWidth: "160px" },
                },
                {
                    key: index + "-20",
                    content: getCommaSeparatorNumber(item.ZAMTTOBECAP),
                    styles: { justifyContent: "end", minWidth: "180px", maxWidth: "180px" },
                },
                {
                    key: index + "-21",
                    // content: item.CUT_OVER_IND,
                    content: item.CUT_OVER_IND === "X" ? "Yes" : "",
                    styles: { minWidth: "110px", maxWidth: "110px" },
                },
            ],
        });
    });

    const getFinalStatusAndRejInd = (approver: number, status: string) => {
        if ((acrReqDetails.data.approver_2_id !== "" && acrReqDetails.data.approver_2_status === "Approved" && approver === 1 && status === "Approved") || (acrReqDetails.data.approver_1_id !== "" && acrReqDetails.data.approver_1_status === "Approved" && approver === 2 && status === "Approved") || (acrReqDetails.data.approver_2_id === "" && approver === 1 && status === "Approved")) {
            return ({
                FINAL_STATUS: "Y",
                VALID_REJ_IND: "",
                ACRSTATUS: "APPROVED",
            });
        } else if ((acrReqDetails.data.approver_2_id !== "" && approver === 1 && status === "Rejected") || (acrReqDetails.data.approver_2_id === "" && approver === 1 && status === "Rejected")) {
            return ({
                FINAL_STATUS: "N",
                VALID_REJ_IND: "X",
                ACRSTATUS: "REJECTED",
            });
        } else if (acrReqDetails.data.approver_1_status !== "" && approver === 2 && status === "Rejected") {
            return ({
                FINAL_STATUS: "N",
                VALID_REJ_IND: "",
                ACRSTATUS: "REJECTED",
            });
        } else {
            return ({
                FINAL_STATUS: "N",
                VALID_REJ_IND: "",
                ACRSTATUS: "PENDING",
            });
        }
    };

    const getEntryTime = (time: string) => {
        const value = time.length === 5 ? "0" + time : time;
        const timeArr = value.split("");
        return timeArr[0] + timeArr[1] + ":" + timeArr[2] + timeArr[3] + ":" + timeArr[4] + timeArr[5];
    };

    const onApproveRejectAcrSapRequest = () => {
        const payload: AcrSapActions.IPostAcrSapRequestUpdatepayload = {
            acr_no: acrNo ? acrNo : acrDetails?.data?.acr_no as string,
            system_id: sysId ? sysId : acrDetails?.data?.system_id as string,
            approverType: userId === getIntegerNoToString(acrReqDetails.data.approver_1_id) ? "approver_1" : "approver_2",
            approver_status: isAction?.action === "approve" ? "Approved" : "Rejected",
            remark: isAction?.remarks as string,
            sap_data: [{
                ...{
                    CLIENTID: acrReqDetails.data.client_id,
                    SYSTEMID: acrReqDetails.data.system_id,
                    ACRNUM: acrReqDetails.data.acr_no,
                    BNAME: acrReqDetails.data.approver_1_id,
                    VALID_DT: userId === getIntegerNoToString(acrReqDetails.data.approver_1_id) ? moment().format("YYYY-MM-DD") : moment(acrReqDetails.data.approver_1_time).format("YYYY-MM-DD"),
                    VALID_TM: userId === getIntegerNoToString(acrReqDetails.data.approver_1_id) ? moment().format("HH:mm:ss") : moment(acrReqDetails.data.approver_1_time).format("HH:mm:ss"),
                    VAL_STATUS: userId === getIntegerNoToString(acrReqDetails.data.approver_1_id) ? (isAction?.action === "approve" ? "Y" : "N") : (acrReqDetails.data.approver_1_status === "Approved" ? "Y" : "N"),
                    REMARKS: userId === getIntegerNoToString(acrReqDetails.data.approver_1_id) ? isAction?.remarks as string : acrReqDetails.data.approver_1_remark,
                    BNAME2: acrReqDetails.data.approver_2_id,
                    APRID_DT2: userId === getIntegerNoToString(acrReqDetails.data.approver_2_id) ? moment().format("YYYY-MM-DD") : "",
                    APRID_TM2: userId === getIntegerNoToString(acrReqDetails.data.approver_2_id) ? moment().format("HH:mm:ss") : "",
                    APR2_STATUS: userId === getIntegerNoToString(acrReqDetails.data.approver_2_id) ? (isAction?.action === "approve" ? "Y" : "N") : "",
                    APR2_REMARKS: userId === getIntegerNoToString(acrReqDetails.data.approver_2_id) ? isAction?.remarks as string : "",
                },
                ...getFinalStatusAndRejInd((userId === getIntegerNoToString(acrReqDetails.data.approver_1_id) ? 1 : 2), (isAction?.action === "approve" ? "Approved" : "Rejected"))
            }]
        };
        dispatch(AcrSapActions.postAcrSapRequestUpdateAction(payload));
        setAppInsightData(payload);
    };

    React.useEffect(() => {
        if (state.acrSapData.postAcrSapRequestUpdate.isSuccess && state.acrSapData.postAcrSapRequestUpdate.status_code === "200" && isAction?.isActive) {
            SuccessToast(state.acrSapData.postAcrSapRequestUpdate.data);
            appInsightData?.approver_status === "Approved" ? trackEvent(appInsights, TraceEvent.ACR_SAP_REQ_APPROVE, appInsightData) : trackEvent(appInsights, TraceEvent.ACR_SAP_REQ_REJECT, appInsightData);
            setIsAction({ isActive: false });
            setAcrDetails !== undefined && setAcrDetails({ isActive: false, refresh: (acrDetails?.refresh === undefined ? 0 : acrDetails?.refresh + 1) });
        } else if (state.acrSapData.postAcrSapRequestUpdate.isSuccess && state.acrSapData.postAcrSapRequestUpdate.status_code !== "200" && isAction?.isActive) {
            ErrorToast(state.acrSapData.postAcrSapRequestUpdate.message);
            appInsightData?.approver_status === "Approved" ? trackEvent(appInsights, TraceEvent.ACR_SAP_REQ_APPROVE_ERROR, { ...state.acrSapData.postAcrSapRequestUpdate, ...appInsightData }) : trackEvent(appInsights, TraceEvent.ACR_SAP_REQ_REJECT_ERROR, { ...state.acrSapData.postAcrSapRequestUpdate, ...appInsightData });
        }
    }, [state.acrSapData.postAcrSapRequestUpdate.isSuccess]);

    React.useEffect(() => {
        const payload: AcrSapActions.IGetAcrSapRequestDetailspayload = { acr_no: acrNo ? acrNo : acrDetails?.data?.acr_no as string, system_id: sysId ? sysId : acrDetails?.data?.system_id as string };
        dispatch(AcrSapActions.getAcrSapRequestDetailsAction(payload));
    }, []);

    return (
        <React.Fragment>
            <Box className="ACR-Asset-Approval">
                <Flex gap="gap.small" vAlign="center" padding="padding.medium">
                    <ArrowLeftIcon styles={({ theme: { siteVariables } }) => ({ color: siteVariables.colorScheme.brand.foreground, cursor: "pointer", paddingTop: "3px" })} onClick={() => !acrNo && setAcrDetails !== undefined ? setAcrDetails({ isActive: false, refresh: acrDetails?.refresh }) : navigate("/acr-initiator")} />
                    <Text size="large" weight="bold" content={acrNo ? acrNo : acrDetails?.data?.acr_no} color="brand" />
                </Flex>
                <Divider fitted />
                <Flex column gap="gap.small" padding="padding.medium" styles={state.appConfig.dimension.deviceWidth >= 600 ? { height: "calc(100vh - 125px)", overflow: "overlay", padding: "0.75rem 1rem" } : { height: "calc(100vh - 175px)", overflow: "overlay", padding: "0.375rem 0.5rem" }}>
                    {state.acrSapData.getAcrSapRequestDetailsData.isSuccess && state.acrSapData.getAcrSapRequestDetailsData.status_code === "200" ?
                        <React.Fragment>
                            <Flex column={state.appConfig.dimension.deviceWidth >= 600 ? false : true} vAlign="center" hAlign="center" space="evenly" styles={{ padding: "0rem 1.5rem" }} >
                                <Flex fill vAlign="center" hAlign="center" >
                                    <Flex column vAlign="center" hAlign="center" >
                                        <Pill
                                            key={"Approve1"}
                                            styles={{
                                                background: acrReqDetails.data.approver_1_status === "Approved" ? "#009E73" : acrReqDetails.data.approver_1_status === "Rejected" ? "rgb(231, 53, 80)" : "",
                                                minWidth: "unset",
                                                color: approvalStatus.some((status) => status === acrReqDetails.data.approver_1_status) ? "white" : "",
                                                fontWeight: approvalStatus.some((status) => status === acrReqDetails.data.approver_1_status) ? "bold" : "lighter",
                                                "&:hover": {
                                                    background: acrReqDetails.data.approver_1_status === "Approved" ? "#009E73" : acrReqDetails.data.approver_1_status === "Rejected" ? "rgb(231, 53, 80)" : "",
                                                    color: approvalStatus.some((status) => status === acrReqDetails.data.approver_1_status) ? "white" : "",
                                                }
                                            }}
                                            content={{
                                                content: "Approver-1",
                                                styles: {
                                                    padding: "0rem 1rem",
                                                    whiteSpace: "nowrap",
                                                },
                                            }}
                                            appearance="outline"
                                        />
                                        <Text content={acrReqDetails.data.approver_1_name} weight="semibold" styles={{ whiteSpace: "nowrap" }} />
                                        {acrReqDetails.data.approver_1_status === "Approved" && <Flex vAlign="center" hAlign="center" gap="gap.smaller"><Text content={moment(acrReqDetails.data.approver_1_time).format("DD-MM-YYYY")} styles={{ whiteSpace: "nowrap" }} /> <CalendarIcon /></Flex>}
                                    </Flex>
                                    {state.appConfig.dimension.deviceWidth >= 600 && acrReqDetails.data.approver_2_id !== "" && < Divider styles={{ width: "100%" }} />}
                                    {acrReqDetails.data.approver_2_id !== "" && <Flex column vAlign="center" hAlign="center" >
                                        <Pill
                                            key={"Approve2"}
                                            styles={{
                                                background: acrReqDetails.data.approver_2_status === "Approved" ? "#009E73" : acrReqDetails.data.approver_2_status === "Rejected" ? "rgb(231, 53, 80)" : "",
                                                minWidth: "unset",
                                                color: approvalStatus.some((status) => status === acrReqDetails.data.approver_2_status) ? "white" : "",
                                                fontWeight: approvalStatus.some((status) => status === acrReqDetails.data.approver_2_status) ? "bold" : "lighter",
                                                "&:hover": {
                                                    background: acrReqDetails.data.approver_2_status === "Approved" ? "#009E73" : acrReqDetails.data.approver_2_status === "Rejected" ? "rgb(231, 53, 80)" : "",
                                                    color: approvalStatus.some((status) => status === acrReqDetails.data.approver_2_status) ? "white" : "",
                                                }
                                            }}
                                            content={{
                                                content: "Approver-2",
                                                styles: {
                                                    padding: "0rem 1rem",
                                                    whiteSpace: "nowrap",
                                                },
                                            }}
                                            appearance="outline"
                                        />
                                        <Text content={acrReqDetails.data.approver_2_name} weight="semibold" styles={{ whiteSpace: "nowrap" }} />
                                        {acrReqDetails.data.approver_2_status === "Approved" && <Flex vAlign="center" hAlign="center" gap="gap.smaller"><Text content={moment(acrReqDetails.data.approver_2_time).format("DD-MM-YYYY")} styles={{ whiteSpace: "nowrap" }} /> <CalendarIcon /></Flex>}
                                    </Flex>}
                                </Flex>
                            </Flex>
                            <Grid className="acr-grid-box" columns={state.appConfig.dimension.deviceWidth <= 600 ? "repeat(1,1fr)" : "repeat(3,1fr)"}>
                                {headerValues.map((item, index) => {
                                    const initialValue = state.acrSapData.getAcrSapRequestDetailsData.data[item.valueKey as keyof typeof state.acrSapData.getAcrSapRequestDetailsData.data] == (undefined || null) ? "" : state.acrSapData.getAcrSapRequestDetailsData.data[item.valueKey as keyof typeof state.acrSapData.getAcrSapRequestDetailsData.data].toString().trimStart();
                                    const value = getValueZeroToConversion(initialValue);
                                    return (
                                        <Flex key={item.key} gap="gap.small" fill column={state.appConfig.dimension.deviceWidth >= 600 ? false : true}>
                                            <Text content={item.title} weight="semibold" className="grid-lable-text" />
                                            {!(item?.type === "textarea") ? <Input className="acr-inputBody" readOnly inverted={(value !== "0") && (value !== "")} fluid value={item.valueKey === "entry_date" ? (value === "0" ? "" : getDDMMYYYFormat(value)) :
                                                item.valueKey === "entry_time" ? getEntryTime(value) :
                                                    item?.comma ? (value === "0" ? "" : getCommaSeparatorNumber(value)) :
                                                        (value === "0" ? "" : value)} />
                                                : <TextArea rows={1} resize="vertical" fluid className="acr-inputBody" readOnly inverted={(value !== "0") && (value !== "")} value={value === "0" ? "" : value} />
                                            }
                                        </Flex>
                                    );
                                })}
                            </Grid>
                            <Flex column>
                                <Menu styles={{ height: "36px", width: "100%", zIndex: 10 }} iconOnly fluid defaultActiveIndex={0} items={items} underlined primary
                                    activeIndex={activeMenuItem} onActiveIndexChange={(_, data) => setActiveMenuItem(String(data?.activeIndex))} />
                                {activeMenuItem === "0" ?
                                    <Flex gap="gap.medium" column>
                                        {dataRows.length > 0 ? <Table
                                            header={{
                                                items: dataHeader,
                                                header: true,
                                                className: "tableHead",
                                                styles: ({ theme: { siteVariables } }) => ({
                                                    background: siteVariables.bodyBackground === "#fff" ? "#f9fbfc" : siteVariables.bodyBackground,
                                                    "&:hover": {
                                                        background: siteVariables.bodyBackground === "#fff" ? "#f9fbfc" : siteVariables.bodyBackground,
                                                    },
                                                }),
                                            }}
                                            rows={dataRows}
                                            className="dataTable"
                                            styles={{ maxHeight: "300px" }} /> :
                                            <DataNotFound message="GR details Not Found" />
                                        }
                                        <Grid className="acr-grid-box" columns={state.appConfig.dimension.deviceWidth <= 600 ? "repeat(1,1fr)" : "repeat(3,1fr)"}>
                                            {grDetailsData.map((item, index) => {
                                                const initialValue = state.acrSapData.getAcrSapRequestDetailsData.data[item.valueKey as keyof typeof state.acrSapData.getAcrSapRequestDetailsData.data] == (undefined || null) ? "" : state.acrSapData.getAcrSapRequestDetailsData.data[item.valueKey as keyof typeof state.acrSapData.getAcrSapRequestDetailsData.data].toString().trimStart();
                                                const value = getValueZeroToConversion(initialValue);
                                                return (
                                                    <Flex key={item.key} gap="gap.small" fill column={state.appConfig.dimension.deviceWidth >= 600 ? false : true}>
                                                        <Text content={item.title} weight="semibold" className="grid-lable-text" />
                                                        <Input className="acr-inputBody" readOnly inverted={(value !== "0") && (value !== "")} fluid value={value === "0" ? "" : value} />
                                                    </Flex>
                                                );
                                            })}
                                        </Grid>
                                    </Flex> :
                                    <Grid className="asset-master-data" columns={state.appConfig.dimension.deviceWidth <= 600 ? "repeat(1,1fr)" : "repeat(3,1fr)"}>
                                        {assetMasterData.map((item, index) => {
                                            const initialValue = state.acrSapData.getAcrSapRequestDetailsData.data.asset_master_data[item.valueKey as keyof typeof state.acrSapData.getAcrSapRequestDetailsData.data.asset_master_data] == (undefined || null) ? "" : state.acrSapData.getAcrSapRequestDetailsData.data.asset_master_data[item.valueKey as keyof typeof state.acrSapData.getAcrSapRequestDetailsData.data.asset_master_data].toString().trimStart();
                                            const UOM = state.acrSapData.getAcrSapRequestDetailsData.data.asset_master_data.MEINS == (undefined || null) ? "" : state.acrSapData.getAcrSapRequestDetailsData.data.asset_master_data?.MEINS.toString().trimStart();
                                            const value = getValueZeroToConversion(initialValue);
                                            return (
                                                <Flex key={item.key} gap="gap.small" column={state.appConfig.dimension.deviceWidth >= 600 ? false : true} >
                                                    <Text content={item.valueKey === "MENGE" ? item.title + "/" + "UOM" : item.title} weight="semibold" className="grid-lable-text" />
                                                    {!(item?.type === "textarea") ? (item.valueKey === "MENGE" ? <Flex gap="gap.small" fill>
                                                        <Input fluid className="acr-inputBody" readOnly inverted={(value !== "0") && (value !== "")} value={value === "0" ? "" : value} />
                                                        <Input fluid className="acr-inputBody" input={{ styles: { maxWidth: "60px", minWidth: "unset" } }} readOnly inverted={UOM !== ""} value={UOM} styles={{ maxWidth: "50px", minWidth: "unset", }} />
                                                    </Flex> :
                                                        <Input fluid className="acr-inputBody" readOnly inverted={(value !== "0") && (value !== "")} value={item.valueKey === "DTUSE" ? (value === ("" || "0") ? "" : getDDMMYYYFormat(value)) : (value === "0" ? "" : value)} />
                                                    ) :
                                                        <TextArea rows={1} resize="vertical" fluid className="acr-inputBody" readOnly inverted={(value !== "0") && (value !== "")} value={value === "0" ? "" : value} />
                                                    }
                                                </Flex>
                                            );
                                        })}
                                    </Grid>}
                            </Flex>
                        </React.Fragment> :
                        state.acrSapData.getAcrSapRequestDetailsData.isSuccess && state.acrSapData.getAcrSapRequestDetailsData.status_code !== "200" ?
                            <ErrorComponent message={state.acrSapData.getAcrSapRequestDetailsData.message} />
                            : <Skeleton animation='pulse'  >
                                <Flex column gap="gap.small" padding="padding.medium">
                                    <Flex space="between" padding="padding.medium">
                                        <Flex gap="gap.small" column vAlign="center">
                                            <Skeleton.Button styles={{ borderRadius: "15px" }} />
                                            <Skeleton.Text style={{ width: "100%" }} />
                                        </Flex>
                                        <Flex gap="gap.small" column vAlign="center">
                                            <Skeleton.Button styles={{ borderRadius: "15px" }} />
                                            <Skeleton.Text style={{ width: "100%" }} />
                                        </Flex>
                                    </Flex>
                                    <Flex column gap="gap.small" padding="padding.medium" >
                                        <Grid className="acr-grid-box" columns={state.appConfig.dimension.deviceWidth <= 600 ? "repeat(1,1fr)" : "repeat(3,1fr)"}>
                                            {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((item, index) => (
                                                <Flex vAlign="center" key={item + "-" + index} gap="gap.small" fill column={state.appConfig.dimension.deviceWidth >= 600 ? false : true}>
                                                    <Skeleton.Text style={{ width: "20%" }} />
                                                    <Skeleton.Input style={{ width: "75%", height: "25px" }} />
                                                </Flex>
                                            ))}
                                        </Grid>
                                    </Flex>
                                    <Flex gap="gap.medium" column padding='padding.medium'>
                                        {[1, 2, 3].map((item, index) => {
                                            return <Skeleton.Line key={item + "-" + index} height="25px" />;
                                        })}
                                    </Flex>
                                    <Flex column gap="gap.small" padding="padding.medium" >
                                        <Grid className="acr-grid-box" columns={state.appConfig.dimension.deviceWidth <= 600 ? "repeat(1,1fr)" : "repeat(3,1fr)"}>
                                            {[1, 2, 3, 4, 5].map((item, index) => (
                                                <Flex vAlign="center" key={item + "-" + index} gap="gap.small" fill column={state.appConfig.dimension.deviceWidth >= 600 ? false : true}>
                                                    <Skeleton.Text style={{ width: "20%" }} />
                                                    <Skeleton.Input style={{ width: "75%", height: "25px" }} />
                                                </Flex>
                                            ))}
                                        </Grid>
                                    </Flex>
                                </Flex>
                            </Skeleton>
                    }
                </Flex>
                <Divider fitted />
                <Flex gap="gap.medium" hAlign="end" padding="padding.medium">
                    <Button content="Approver's Remark" disabled={state.acrSapData.getAcrSapRequestDetailsData.isSuccess && acrReqDetails.data.approver_1_status !== ("" || null) ? false : true} onClick={() => setIsAction({ isApproverActive: true })} />
                    {(!acrNo && !initiator) && <Flex gap="gap.medium" hAlign="end" >
                        <Button content="Reject" disabled={!state.acrSapData.getAcrSapRequestDetailsData.isSuccess || (userId === getIntegerNoToString(acrReqDetails.data.approver_1_id) ? approvalStatus.some((status) => status === acrReqDetails.data.approver_1_status) : (approvalStatus.some((status) => status === acrReqDetails.data.approver_2_status) || !approvalStatus.some((status) => status === acrReqDetails.data.approver_1_status) || "Rejected" === acrReqDetails.data.approver_1_status))} secondary onClick={() => setIsAction({ isActive: true, action: "reject" })} />
                        <Button content="Approve" disabled={!state.acrSapData.getAcrSapRequestDetailsData.isSuccess || (userId === getIntegerNoToString(acrReqDetails.data.approver_1_id) ? approvalStatus.some((status) => status === acrReqDetails.data.approver_1_status) : (approvalStatus.some((status) => status === acrReqDetails.data.approver_2_status) || !approvalStatus.some((status) => status === acrReqDetails.data.approver_1_status) || "Rejected" === acrReqDetails.data.approver_1_status))} primary onClick={() => setIsAction({ isActive: true, action: "approve" })} />
                    </Flex>}
                </Flex>
                <Dialog
                    open={isAction?.isApproverActive} header={"ACR No." + " " + (acrReqDetails?.data?.acr_no)}
                    headerAction={{
                        content: <CloseIcon />,
                        title: "Close Dialog",
                        onClick: () => setIsAction({ isApproverActive: false }),
                        styles: { cursor: "pointer" }
                    }}
                    content={
                        <Flex vAlign="center" column gap="gap.small">
                            <Divider fitted />

                            <Flex vAlign="center" column gap="gap.small" styles={{ border: "1px solid #DDDDDD", padding: "10px" }}>
                                <Flex vAlign="center" gap="gap.small" space="between">
                                    <Text> <Text content="Approver 1 : " weight="semibold" /> {acrReqDetails?.data?.approver_1_name}</Text>
                                    <Text> <Text content="Status : " weight="semibold" /> {acrReqDetails?.data?.approver_1_status}</Text>
                                </Flex>
                                <Text content="Remark" weight="semibold" />
                                <Box styles={{ padding: "8px", backgroundColor: "rgb(243, 242, 241)", minHeight: "45px", borderRadius: "4px" }}>
                                    <Text content={acrReqDetails?.data?.approver_1_remark} />
                                </Box>
                            </Flex>
                            {acrReqDetails?.data?.approver_2_remark && (
                                <Flex vAlign="center" column gap="gap.small" styles={{ border: "1px solid #DDDDDD", padding: "10px" }}>
                                    <Flex vAlign="center" gap="gap.small" space="between">
                                        <Text> <Text content="Approver 2 : " weight="semibold" /> {acrReqDetails?.data?.approver_2_name}</Text>
                                        <Text> <Text content="Status : " weight="semibold" /> {acrReqDetails?.data?.approver_2_status}</Text>
                                    </Flex>
                                    <Text content="Remark" weight="semibold" />
                                    <Box styles={{ padding: "8px", backgroundColor: "rgb(243, 242, 241)", minHeight: "45px", borderRadius: "4px" }}>
                                        <Text content={acrReqDetails?.data?.approver_2_remark} />
                                    </Box>
                                </Flex>
                            )}
                        </Flex>
                    }
                    cancelButton="Close"
                    onCancel={() => setIsAction({ isApproverActive: false })}
                />
                <Dialog open={isAction?.isActive} header={"ACR " + (isAction?.action === "approve" ? "Approval" : "Reject")}
                    headerAction={{
                        content: <CloseIcon />,
                        title: "Close Dialog",
                        onClick: () => setIsAction({ isActive: false }),
                        styles: { cursor: "pointer" }
                    }}
                    content={<Flex vAlign="center" column gap="gap.small">
                        <Text content={"ACR No. - " + acrDetails?.data?.acr_no} weight="semibold" />
                        <Text weight="semibold" >Remarks {<Text content="*" color="red" />}</Text>
                        <TextArea className="acr-inputBody" maxLength={50} value={isAction?.remarks} onChange={(e, v) => v?.value === null ? setIsAction({ ...isAction, remarks: "" }) : setIsAction({ ...isAction, remarks: v?.value?.trimStart() })} />
                        {isAction?.remarks?.length === 50 && <Text content="The character limit should not be greater than 50" color="red" />}
                    </Flex>}
                    cancelButton="Cancel"
                    confirmButton={{
                        content: "Confirm",
                        disabled: isAction?.remarks === "" || isAction?.remarks === undefined || isAction.remarks === null,
                        loading: state.acrSapData.postAcrSapRequestUpdate.isLoading
                    }}
                    onConfirm={() => onApproveRejectAcrSapRequest()}
                    onCancel={() => setIsAction({ isActive: false })}
                />
            </Box>
        </React.Fragment >
    );
};
export default ACRRequestDetails;
