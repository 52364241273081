import React from "react";
import { Dropdown, Menu, Segment, Flex, Text, Divider, Box, Table, Checkbox, gridCellWithFocusableElementBehavior, ComponentVariablesObject, ChevronStartIcon, SearchIcon, Input, FlexItem, Header, ArrowLeftIcon, Button, ChevronEndIcon, Skeleton, } from "@fluentui/react-northstar";
import { IState } from "../../App";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootSchema } from "../../redux/reducer";
import "./acrApprovals.scss";
import ACRRequestDetails from "../../components/ACRRequestDetails";
import * as AcrSapActions from "../../redux/actions/AcrSapActions";
import DataNotFound from "../../components/DNF";
import ErrorComponent from "../../components/ErrorComponent";
import moment from "moment";
interface IAcrApprovals {
    QueryData: IState;
    initiator?: boolean
}

export interface IAcrRequestDetails {
    isActive: boolean;
    data?: AcrSapActions.IGetAcrSapApprovalListItem;
    refresh?: number
}

const ACRApprovals: React.FC<IAcrApprovals> = ({ QueryData, initiator }) => {
    const [page, setPage] = React.useState<{ listPerPage: number; currentPage: number }>({ listPerPage: 10, currentPage: 0 });
    const [acrDetails, setAcrDetails] = React.useState<IAcrRequestDetails>();
    const [filters, setFilters] = React.useState<{ searchAcr: string, status: string }>({
        searchAcr: "", status: "Pending"
    });

    const dispatch = useDispatch();
    const state = useSelector((state: RootSchema) => state);

    const dataHeader = [
        {
            key: "h1",
            content: <Text size="medium" weight="bold" content="Serial No." />,
            styles: { margin: "0px", minWidth: "82px", maxWidth: "82px", justifyContent: "center" },
        },
        {
            key: "h2",
            content: <Text size="medium" weight="bold" content="ACR No." />,
            styles: { margin: "0px", minWidth: "110px", maxWidth: "110px", }
        },
        {
            key: "h11",
            content: <Text size="medium" weight="bold" content="ACR Amount" />,
            styles: { margin: "0px", minWidth: "105px", maxWidth: "105px" },
        },
        {
            key: "h3",
            content: <Text size="medium" weight="bold" content="System ID" />,
            styles: { margin: "0px", minWidth: "85px", maxWidth: "85px" },
        },
        {
            key: "h4",
            content: <Text size="medium" weight="bold" content="WBS Elements" />,
            styles: { margin: "0px", minWidth: "120px", maxWidth: "120px" },
        },
        {
            key: "h5",
            content: <Text size="medium" weight="bold" content="WBS Description" />,
            styles: { margin: "0px", minWidth: "170px", maxWidth: "170px" },
        },
        {
            key: "h6.1",
            content: <Text size="medium" weight="bold" content="Asset Category" />,
            styles: { margin: "0px", minWidth: "120px", },
        },
        {
            key: "h6",
            content: <Text size="medium" weight="bold" content="Requestor Name" />,
            styles: { margin: "0px", minWidth: "130px", },
        },
        {
            key: "h7",
            content: <Text size="medium" weight="bold" content="Status" />,
            styles: { margin: "0px", minWidth: "85px", maxWidth: "85px" },
        },
        {
            key: "h8",
            content: <Text size="medium" weight="bold" content="Entry Date" />,
            styles: { margin: "0px", minWidth: "90px", maxWidth: "90px" },
        },
        {
            key: "h9",
            content: <Text size="medium" weight="bold" content="Approver 1" />,
            styles: { margin: "0px", minWidth: "125px" },
        },
        {
            key: "h10",
            content: <Text size="medium" weight="bold" content="Approver 2" />,
            styles: { margin: "0px", minWidth: "125px", },
        }
    ];

    const acrList = !initiator ? state.acrSapData.getAcrSapApprovalList : state.acrSapData.getAcrSapRequestInitiatorData;
    const totalRecords = acrList.isSuccess && acrList.status_code === "200" ? acrList.data[0][0].RowCount : 1;
    const dataRows = acrList.isSuccess && acrList.status_code === "200" && acrList.data[1].length > 0 ?
        acrList.data[1].map((item: AcrSapActions.IGetAcrSapApprovalListItem | AcrSapActions.IGetAcrSapRequestInitiatorItem, index) => {
            return ({
                key: index,
                items: [
                    {
                        key: index + "-1",
                        content: index + 1,
                        styles: { margin: "0px", minWidth: "82px", maxWidth: "82px", justifyContent: "center" },
                    },
                    {
                        key: index + "-2",
                        truncateContent: true,
                        content: item.acr_no,
                        title: item.acr_no,
                        styles: { margin: "0px", minWidth: "110px", maxWidth: "110px" },
                    },
                    {
                        key: index + "-11",
                        truncateContent: true,
                        content: item.acr_amount,
                        title: item.acr_amount,
                        styles: { margin: "0px", minWidth: "105px", maxWidth: "105px" },
                    },
                    {
                        key: index + "-3",
                        truncateContent: true,
                        content: item.system_id.substring(0, 3) + "-" + item.system_id.substring(3, 6),
                        title: item.system_id.substring(0, 3) + "-" + item.system_id.substring(3, 6),
                        styles: { margin: "0px", minWidth: "85px", maxWidth: "85px" },
                    },
                    {
                        key: index + "-4",
                        truncateContent: true,
                        content: item.wbs_ele,
                        title: item.wbs_ele,
                        styles: { margin: "0px", minWidth: "120px", maxWidth: "120px" },
                    },
                    {
                        key: index + "-5",
                        truncateContent: true,
                        content: item.wbs_des,
                        title: item.wbs_des,
                        styles: { margin: "0px", minWidth: "170px", maxWidth: "170px" },
                    },
                    {
                        key: index + "-6.1",
                        truncateContent: true,
                        content: item.asset_category,
                        title: item.asset_category,
                        styles: { margin: "0px", minWidth: "120px", },
                    },
                    {
                        key: index + "-6",
                        truncateContent: true,
                        content: item.requestor_name,
                        title: item.requestor_name,
                        styles: { margin: "0px", minWidth: "130px", },
                    },
                    {
                        key: index + "-7",
                        truncateContent: true,
                        content: item.approval_status,
                        title: item.approval_status,
                        // content: item.status,
                        // title: item.status,
                        styles: { margin: "0px", minWidth: "85px", maxWidth: "85px" },
                    },
                    {
                        key: index + "-8",
                        truncateContent: true,
                        content: moment(item.entry_date).format("DD-MM-YYYY"),
                        title: moment(item.entry_date).format("DD-MM-YYYY"),
                        styles: { margin: "0px", minWidth: "90px", maxWidth: "90px" },
                    },
                    {
                        key: index + "-9",
                        truncateContent: true,
                        content: item.approver_1_name,
                        title: item.approver_1_name,
                        styles: { margin: "0px", minWidth: "122px", },
                    },
                    {
                        key: index + "-10",
                        truncateContent: true,
                        content: item.approver_2_name,
                        title: item.approver_2_name,
                        styles: { margin: "0px", minWidth: "122px" },
                    },
                ],
                onClick: () => setAcrDetails({ ...acrDetails, isActive: true, data: item }),
                className: `acrStatus ${item.approval_status === "Approved" ? "approved" : item.approval_status === "Rejected" ? "rejected" : "pending"}`,
                styles: { cursor: "pointer" }
            });
        }) : [];

    React.useEffect(() => {
        setFilters({ searchAcr: "", status: "Pending" });
    }, [initiator]);

    React.useEffect(() => {
        const payload: AcrSapActions.IGetAcrSapApprovalListpayload = {
            empId: QueryData.employeeId as string,
            start_index: (page.currentPage * page.listPerPage),
            page_size: page.listPerPage,
            status: filters.status,
            search: "%" + filters.searchAcr + "%"
        };
        !initiator ? dispatch(AcrSapActions.getAcrSapApprovalListAction(payload)) : dispatch(AcrSapActions.getAcrSapRequestInitiatorAction(payload));
        acrDetails?.isActive && setAcrDetails({ isActive: false });
    }, [page, filters.status, filters.searchAcr, acrDetails?.refresh, initiator]);

    return (
        <React.Fragment>
            <Segment className="ACR-Approvals" >
                {!acrDetails?.isActive ?
                    <React.Fragment>
                        <Flex gap="gap.small" vAlign="center" padding="padding.medium">
                            {/* <ArrowLeftIcon styles={({ theme: { siteVariables } }) => ({ color: siteVariables.colorScheme.brand.foreground, cursor: "pointer", paddingTop: "3px" })} onClick={() => navigate("/manager-view")} /> */}
                            <Text size="large" weight="bold" content={!initiator ? "ACR Approvals" : "ACR Requests"} color="brand" styles={{ whiteSpace: "nowrap" }} />
                        </Flex>
                        <Divider fitted />
                        <Flex fill gap='gap.medium' className="Acr-filter-box" column={state.appConfig.dimension.deviceWidth >= 600 ? false : true}>
                            {/* <Flex vAlign="center" gap="gap.smaller" fill wrap={state.appConfig.dimension.deviceWidth >= 600 ? false : true}>
                                <Text content="Company Name" weight="semibold" styles={{ whiteSpace: "nowrap" }} />
                                <Dropdown className="inputBody" as={Flex} fill fluid inverted placeholder="TMPV" items={[]} />
                            </Flex> */}
                            <Flex vAlign="center" gap="gap.smaller" fill wrap={state.appConfig.dimension.deviceWidth >= 600 ? false : true}>
                                <Text content="ACR Number" weight="semibold" styles={{ whiteSpace: "nowrap" }} />
                                <Input className="inputBody" value={filters.searchAcr} clearable={filters.searchAcr !== ""} icon={<SearchIcon />} fluid inverted placeholder="Please Search ACR No. Request.." onChange={(e, v) => v?.value === null ? setFilters({ ...filters, searchAcr: "" }) : setFilters({ ...filters, searchAcr: v?.value.trimStart() as string })} />
                            </Flex>
                            <Flex vAlign="center" gap="gap.smaller" fill wrap={state.appConfig.dimension.deviceWidth >= 600 ? false : true}>
                                <Text content="Status" weight="semibold" styles={{ whiteSpace: "nowrap" }} />
                                <Dropdown className="inputBody" as={Flex} fill fluid inverted placeholder="All Status" items={[
                                    "Pending",
                                    "Approved",
                                    "Rejected"
                                ]}
                                    clearable={filters.status !== "All"}
                                    value={filters.status}
                                    onChange={(e, v: any) => v?.value === null ? setFilters({ ...filters, status: "All" }) : setFilters({ ...filters, status: v?.value.trimStart() as string })}
                                />
                            </Flex>
                        </Flex>
                        <Divider fitted />
                        <Box styles={{ height: "calc(100vh - 187px)", overflow: "auto" }}>
                            {acrList.isSuccess && acrList.status_code === "200" && dataRows.length > 0 ?
                                <Table
                                    header={{
                                        items: dataHeader,
                                        header: true,
                                        className: "tableHead",
                                        styles: ({ theme: { siteVariables } }) => ({
                                            paddingLeft: "5px",
                                            background: siteVariables.bodyBackground === "#fff" ? "#f9fbfc" : siteVariables.bodyBackground,
                                            "&:hover": { background: siteVariables.bodyBackground === "#fff" ? "#f9fbfc" : siteVariables.bodyBackground, },
                                        }),
                                    }}
                                    rows={dataRows}
                                    className="dataTable"
                                    styles={{ height: "100%" }} /> :
                                acrList.isSuccess && acrList.status_code === "200" && dataRows.length === 0 ?
                                    <DataNotFound /> :
                                    acrList.isSuccess && acrList.status_code !== "200" ?
                                        <ErrorComponent message={acrList.message} /> :
                                        <Skeleton animation="pulse" >
                                            <Flex gap="gap.medium" column padding='padding.medium'>
                                                {[1, 2, 3, 4, 5, 6].map((item) => {
                                                    return <Skeleton.Line key={item} height="25px" />;
                                                })}
                                            </Flex>
                                        </Skeleton>}
                        </Box>
                        <Divider fitted />
                        <Flex gap="gap.small" vAlign="center" fill hAlign="end" padding="padding.medium">
                            <Text content="Items Per Page:" weight="semibold" />
                            <Dropdown inverted items={[10, 15, 20, 25]} value={page.listPerPage} className="inputBody" fluid styles={{ width: "70px" }} onChange={(e: any, v: any) => setPage({ ...page, listPerPage: v.value })} />
                            <Button icon={<ChevronStartIcon />} iconOnly style={{ color: "#6264a7" }} disabled={page.listPerPage * page.currentPage > 0 ? false : true}
                                onClick={() => setPage({ ...page, currentPage: page.currentPage - 1 })}
                            />
                            {acrList.isLoading ? <Skeleton animation="wave" styles={{ width: "35px" }} ><Skeleton.Text /></Skeleton> :
                                <Text>{page.currentPage + 1} of{" "}{Math.ceil(totalRecords / page.listPerPage)}</Text>}
                            <Button icon={<ChevronEndIcon />} iconOnly style={{ color: "#6265a7" }}
                                disabled={Math.ceil(totalRecords / page.listPerPage) <= page.currentPage + 1 ? true : false}
                                onClick={() => setPage({ ...page, currentPage: page.currentPage + 1 })}
                            />
                        </Flex>
                    </React.Fragment> : <ACRRequestDetails QueryData={QueryData} acrDetails={acrDetails} setAcrDetails={setAcrDetails} initiator={initiator} />}
            </Segment>
        </React.Fragment >
    );
};

export default ACRApprovals;
