import React, { Suspense } from "react";
import { Button, Flex, Text, Menu, tabListBehavior, Divider, Skeleton } from "@fluentui/react-northstar";
import { ChevronStartIcon } from "@fluentui/react-icons-northstar";
import { useNavigate } from "react-router-dom";
import { IState } from "../../App";
import { useSelector, useDispatch } from "react-redux";
import { RootSchema } from "../../redux/reducer";
import { NFA_BASE_URL } from "../../config";
import LazyFallBack from "../../components/LazyFallBack";

type Props = {
    QueryData: IState;
};

const NFA: React.FC<Props> = ({ QueryData }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const ValidUser = useSelector((root: RootSchema) => root.authData.nfaUserAuth.data.filter((item) => item?.access_token))[0];
   const userName=useSelector((root: RootSchema) => root.userInfo.userManagerData.user_details[0].CompName);
    return (
        <Flex gap="gap.medium" className='space-container'>
            <Flex fill column>
                <Flex padding="padding.medium" vAlign="center" gap="gap.smaller" styles={{ cursor: "pointer", "&:hover": { color: "blue", textDecoration: "underline" } }} onClick={() => navigate("/")}>
                    <ChevronStartIcon color="brand" />
                    <Text content="Overview" size="medium" weight="bold" />
                </Flex>
                <Divider fitted />
                <Suspense fallback={<LazyFallBack />}>
                    <iframe src={NFA_BASE_URL + "/?email=" + ValidUser.email + `&username=${userName}&theme=${QueryData.theme}`} height="100vh"
                        frameBorder={"0"}
                        allowFullScreen
                        loading="lazy" style={{ minHeight: "calc(100vh - 42px)", border: "none" }} width="100%" />
                </Suspense>
            </Flex>
        </Flex>
    );
};

export default NFA;