import React from "react";
import {
    Provider, teamsTheme, teamsDarkTheme,
    teamsHighContrastTheme,
    Dialog,
    Button,
    Flex,
    Text,
} from "@fluentui/react-northstar";
import * as microsoftTeams from "@microsoft/teams-js";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import RouteList from "./routes";
import "react-toastify/dist/ReactToastify.css";
import "./App.scss";
import * as AuthActions from "./redux/actions/AuthActions";
import * as ITSMActions from "./redux/actions/ITSMAction";
import * as AcrSapActions from "./redux/actions/AcrSapActions";
import { RootSchema } from "./redux/reducer";
import { getUserManagerAction } from "./redux/actions/UserDataActions";
import { getLeaveMasterAction, getEmployeeIdUsingGraphAction, getActiveYearAction } from "./redux/actions/LeaveActions";
import { EmpValueByCode } from "./constant";
import axios, { Method, AxiosRequestHeaders } from "axios";
import { setDimensionByDevice, setPath } from "./redux/actions/appConfigration";
import AccessDenied from "./components/AccessDenied";
import { TraceEvent, TraceTelemtry } from "./constant";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin, AppInsightsContext, useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { ai, getAppInsights } from "./telemetry/telemetryService";
import LazyFallBack from "./components/LazyFallBack";

import { VC_BASE_URL, ITSM_API_CONFIG, USER_TOKEN_API, API_SECRET_TOKEN } from "./config";
import { getServiceAvailabilityDataAction } from "./redux/actions/serviceAction";
import { getPinDataAction } from "./redux/actions/PinActions";
export interface IState {
    employeeId?: string | null;
    userId?: string | null;
    token: string | null;
    domain: string | null;
    appId: string | null;
    isOnTeams: boolean;
    isTaskModuleActive?: boolean;
    azureADToken?: string;
    app_env?: string;
    userObjectId?: string | any;
    upn?: string[];
    theme: string;
    azureADSharepointToken?: string;
}
//ai.initialize()
const getQueryVariable = (variable: string): string | null => {
    const query = window && window.location.search.substring(1);
    const vars = query.split("&");
    for (const varPairs of vars) {
        const pair = varPairs.split("=");
        if (decodeURIComponent(pair[0]) === variable) {
            return decodeURIComponent(pair[1]);
        }
    }
    return null;
};
function App() {
    const { appInsights, trackEvent, trackMetrix } = getAppInsights();
    const [isLogin, setIsLogin] = React.useState<boolean>(false);
    const [isTaskModule, setIsTaskModule] = React.useState(false);
    const [isError, setIsError] = React.useState<boolean>(false);
    const [accessToken, setAccessToken] = React.useState("");
    const [contextGet, setContextGet] = React.useState(null);
    const [idleTime, setIdleTime] = React.useState<{ isActive: boolean; isLoading: boolean; isError: boolean; }>({ isActive: false, isLoading: false, isError: false });
    const [state, setState] = React.useState<IState>({
        // token: (process.env.REACT_APP_ENV === "production" ? process.env.REACT_APP_PROD_TOKEN : process.env.REACT_APP_TOKEN) as string,
        token: "",
        domain: `https://${process.env.REACT_APP_API_URL}:5002`,
        appId: (process.env.REACT_APP_ENV === "production" ? process.env.REACT_APP_PROD_ID : process.env.REACT_APP_ID) as string,
        employeeId: "",
        userId: "",
        isOnTeams: false,
        isTaskModuleActive: false,
        app_env: getQueryVariable("app_env") || "",
        upn: [""],
        theme: "default",
    });
    const [theme, setTheme] = React.useState(teamsTheme);

    const dispatch = useDispatch();
    const rootState = useSelector((root: RootSchema) => root);

    toast.configure({
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        newestOnTop: false,
        closeOnClick: true,
        rtl: false,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        theme: "colored",
    });

    microsoftTeams.initialize();

    const updateTheme = (themeStr?: string | null): void => {
        switch (themeStr) {
            case "dark":
                setTheme(teamsDarkTheme);
                break;
            case "contrast":
                setTheme(teamsHighContrastTheme);
                break;
            case "default":
            default:
                setTheme(teamsTheme);
        }
        //trackTrace(appInsights, TraceTelemtry.THEME, contextGet)
    };

    if (state.isOnTeams) {
        microsoftTeams.registerOnThemeChangeHandler((theme: string) => {
            updateTheme(theme);
            setState((prev) => ({ ...prev, theme: theme }));
        });
    }

    const getNavigatorInfo = () => {
        const { userAgent } = navigator;
        if (userAgent.match(/Teams/gi)) setState((prev) => ({ ...prev, isOnTeams: true }));
        else setState((prev) => ({ ...prev, isOnTeams: false }));
    };



    const getClientSideToken = (context: any) => {
        let ssoURL = "";
        const app_env = getQueryVariable("app_env");
        if (process.env.REACT_APP_ENV === "production") {
            ssoURL = `api://${process.env.REACT_APP_PROD_VACCINATION_API_URL}/${process.env.REACT_APP_PROD_ID}`;
        }
        else if (app_env === "staging" && process.env.REACT_APP_ENV !== "production") {
            ssoURL = "api://drishtiteamsdev.tatamotors.com/63cc4e19-b947-4761-88a6-b48aebcd91f7";
        }
        else if (process.env.REACT_APP_ENV !== "production") {
            ssoURL = "api://drishtiteamsdev.tatamotors.com/b77095d2-4252-4fa0-abed-67a404a7ebf0";
        }
        return new Promise((resolve, reject) => {
            microsoftTeams.authentication.getAuthToken({
                successCallback: (result: any) => {
                    trackEvent(appInsights, TraceEvent.SSO_SUCCESS, { result, context });
                    trackMetrix(appInsights, { name: "ssoLogin", average: 1 }, context);
                    resolve(result);
                },
                failureCallback: function (error: any) {
                    trackEvent(appInsights, TraceEvent.SSO_SUCCESS_ISSUE, { error, context });
                    reject("Error getting token: " + error);
                },
                resources: [
                    ssoURL,
                ],
            });
        });
    };

    const tokenExchange = async (token: any) => {
        return await axios.post(
            `${process.env.REACT_APP_ENV === "production" ? process.env.REACT_APP_PROD_VACCINATION_API_URL : process.env.REACT_APP_ENV === "stag" ? process.env.REACT_APP_STAG_VACCINATION_API_URL : process.env.REACT_APP_VACCINATION_API_URL}${process.env.REACT_APP_ENV === "production" ? "/api/azure-token/exchange/azure-token-exchange" : "/api/azure-token/exchange/development-azure-token-exchange"}`,
            { token: token }
        );
    };

    // const tokenExchangeSharepoint = async (token: any) => {
    //     return await axios.post(
    //         `${process.env.REACT_APP_ENV === "production" ? process.env.REACT_APP_PROD_VACCINATION_API_URL : process.env.REACT_APP_ENV === "stag" ? process.env.REACT_APP_STAG_VACCINATION_API_URL : process.env.REACT_APP_VACCINATION_API_URL}${process.env.REACT_APP_ENV === "production" ? "/api/azure-token/exchange/azure-token-exchange-sharepoint" : "/api/azure-token/exchange/development-azure-token-exchange-sharepoint"}`,
    //         { token: token }
    //     );
    // };

    //tml-drishti-lx-bot.azurewebsites.net
    // const tokenExchange = async(token: any)=>{
    //   console.log(`${process.env.REACT_APP_VACCINATION_API_URL}/api/token-exchange`)
    //   return await axios.post(
    //     `${process.env.REACT_APP_VACCINATION_API_URL}/api/token-exchange`,
    //     { token: token }
    //   );
    // }    console.log("Window Deminsion", window.innerWidth, window.innerHeight)
    async function refreshToken() {
        setIdleTime({ ...idleTime, isLoading: true });
        const apis: { id: string; method: Method; url: string; payload: object; config: AxiosRequestHeaders; success?: any; }[] = [
            {
                id: "subsidiary",
                method: "post",
                url: VC_BASE_URL + USER_TOKEN_API,
                payload: {
                    empId: state.employeeId,
                    platform: "tml-dashboard",
                    username: state.userId,
                },
                config: {
                    // Authorization: `${state.token}`
                    token: API_SECRET_TOKEN as string,
                    "x-teams-origin": window.location.origin
                },
                success: (q: any) => dispatch(AuthActions.getUserTokenSuccessAction(q)),
            },
            {
                id: "itsmToken",
                method: "get",
                url: ITSM_API_CONFIG.GET_TOKEN,
                payload: {},
                config: {},
                success: (q: any) => dispatch(ITSMActions.getTokenSuccessAction(q)),
            }
        ];
        Promise.all(apis.map((endpoint) => axios(
            endpoint.url,
            {
                method: endpoint.method,
                data: endpoint.payload,
                headers: endpoint.config
            }
        ))).then((req: any[]) => {
            req.map((result: any, index: number) => {
                if (result.statusText === "OK" && result.status == 200) {
                    console.log("result", index);
                    return apis[index].success(result.data);
                } else {
                    return apis[index].success(result.data);
                }
            });
            console.log("data", req);
            return req.every((item) => item.statusText === "OK") ? setIdleTime({ ...idleTime, isActive: false, isLoading: false, isError: false }) : setIdleTime({ ...idleTime, isLoading: false, isError: true });
        });
    }

    const inactivityTime = function () {
        let time: any;
        window.addEventListener("load", resetTimer, true);
        const events = ["mousedown", "mousemove", "keypress", "scroll", "touchstart"];
        events.forEach(function (name) {
            document.addEventListener(name, resetTimer, true);
        });
        function idleCall() {
            if (!idleTime.isActive) setIdleTime({ ...idleTime, isActive: true });
        }
        function resetTimer() {
            clearTimeout(time);
            time = setTimeout(idleCall, 1000 * 60 * 15);
            // time = setTimeout(idleCall, 10000);
        }
    };
    if (rootState.userInfo.userManagerData.isSuccess) inactivityTime();

    React.useEffect(() => {
        const value = {
            deviceWidth: window.innerWidth,
            deviceHeight: window.innerHeight
        };
        dispatch(setDimensionByDevice(value));
    }, [window.innerWidth]);

    React.useEffect(() => {
        const empId = getQueryVariable("employeeId");
        const userId = getQueryVariable("userid");
        const app_env = getQueryVariable("app_env") || "";
        const upn = getQueryVariable("upn") || [];
        // if (empId !== null && userId !== null && state.employeeId === '' && state.userId === '' && navigator.userAgent.match(/Teams/gi)) {
        if (empId !== null && userId !== null && state.employeeId === "" && state.userId === "") {
            setState((prev) => ({
                ...prev,
                employeeId: empId,
                userId: userId,
                isTaskModuleActive: true,
                app_env: app_env,
                upn: upn as string[]
            }));
            setIsTaskModule(true);
            setIsLogin(true);
            (upn.length && upn !== undefined) && dispatch(AuthActions.getNFAUserValidateTokenAction(JSON.parse(upn as string)));
        }
    }, [state]);

    React.useEffect(() => {
        getNavigatorInfo();
        new Promise((resolve, reject) => {
            microsoftTeams.getContext(resolve);
            microsoftTeams.getContext(reject);
        })
            .then((context: any) => {
                if (context.subEntityId !== "") {
                    dispatch(setPath(context.subEntityId));
                }
                setContextGet(context);
                getClientSideToken(context).then((token: any) => {
                    tokenExchange(token).then((res: any) => {
                        dispatch(
                            getEmployeeIdUsingGraphAction(
                                res.data.access_token,
                                context.userPrincipalName
                            )
                        );
                        setState((prev) => ({ ...prev, azureADToken: res.data.access_token, userObjectId: context.userObjectId, theme: context.theme }));
                        setAccessToken(token);
                        setIsLogin(true);
                    }).catch(err => {
                        trackEvent(appInsights, TraceEvent.SSO_SUCCESS_ISSUE, { err, contextGet });
                        setIsError(true);
                    });
                    // tokenExchangeSharepoint(token).then((res: any) => {
                    //     setState((prev) => ({ ...prev, azureADSharepointToken: res.data.access_token }));
                    // }).catch(err => {
                    //     console.log("Error", err);
                    // });
                }).catch((err) => {
                    trackEvent(appInsights, TraceEvent.SSO_SUCCESS_ISSUE, { err, contextGet });
                    setIsError(true);
                });
                updateTheme(context.theme);
                microsoftTeams.registerOnThemeChangeHandler((theme: string) => {
                    updateTheme(theme);
                    setState((prev) => ({ ...prev, theme: theme }));
                });
            })
            .catch((err) => {
                console.log("Error in Context", err);
                trackEvent(appInsights, TraceEvent.SSO_SUCCESS_ISSUE, { err });
                setIsError(true);
            });
    }, []);

    // React.useEffect(() => {
    //   if (state.token !== '' && state.userId !== '') {
    //     trackEvent(appInsights, TraceEvent.TML_TASK_MODULE, { userId: state.userId, path: window && window.location.pathname })
    //     dispatch(AuthActions.getUserTokenAction(state.token as string, state.employeeId, state.userId as string));
    //   }
    // }, [state.token, state.userId]);

    React.useEffect(() => {
        if (state.employeeId !== "" && state.userId !== "") dispatch(getPinDataAction(state.employeeId as string));
    }, [state.employeeId]);

    React.useEffect(() => {
        if (rootState.authData.userAuth.isSuccess && rootState.authData.userAuth.status_code === "200" && rootState.authData.userAuth.token) {
            dispatch(getUserManagerAction(rootState.authData.userAuth.token, state.employeeId));
            dispatch(AcrSapActions.getAcrSapEmpRowCountAction({ empId: state.employeeId as string }));
            !rootState.serviceData.getServiceData.services_status && dispatch(getServiceAvailabilityDataAction(rootState.authData.userAuth.token, EmpValueByCode[rootState.authData.userAuth.comp_code as keyof typeof EmpValueByCode]));
        }
    }, [rootState.authData.userAuth.isSuccess && rootState.authData.userAuth.status_code === "200"]);

    React.useEffect(() => {
        if (rootState.userInfo.userManagerData.isSuccess && rootState.userInfo.userManagerData.status_code === "200") {
            const payload = {
                emp_id: state.employeeId as string,
                pa: rootState.userInfo.userManagerData.user_details[0]["PersArea"],
                psa: rootState.userInfo.userManagerData.user_details[0]["PSubarea"],
            };
            dispatch(getLeaveMasterAction(rootState.authData.userAuth.token, EmpValueByCode[rootState.authData.userAuth.comp_code as keyof typeof EmpValueByCode], payload));
            dispatch(getActiveYearAction(rootState.authData.userAuth.token, EmpValueByCode[rootState.authData.userAuth.comp_code as keyof typeof EmpValueByCode]));
            // String(employeeData.manager.mailNickname).toLowerCase()//||rootState.userInfo.userManagerData.manager_details[0].Imailid.split("@")[0]
            if (!isTaskModule && rootState.userInfo.userManagerData.user_details[0].Reporting !== "00000000") {
                const employeeData = rootState.leaveData.employeeData;
                // Need to be correct on Production env. Temp Solution
                const empId = Array.isArray(rootState.userInfo.userManagerData.manager_details) && rootState.userInfo.userManagerData.manager_details[0]?.Perno || "00000000";
                if (employeeData.manager && employeeData.manager?.userPrincipalName) {
                    const userID = String(employeeData.manager.userPrincipalName || "").split("@");
                    dispatch(AuthActions.getManagerTokenAction("00" + parseInt(empId).toString(), String(userID[0]).toLowerCase()));
                }
            }
        }
    }, [rootState.userInfo.userManagerData.isSuccess && rootState.userInfo.userManagerData.status_code === "200"]);

    React.useEffect(() => {
        if (!isTaskModule && rootState.leaveData.employeeData && rootState.leaveData.employeeData.isSuccess) {
            const employeeData = rootState.leaveData.employeeData;
            const userID = String(employeeData.userPrincipalName || "").split("@");
            setState((prev) => ({ ...prev, employeeId: "00" + employeeData.employeeId, userId: userID[0], upn: [employeeData.userPrincipalName, employeeData.mail] }));
            dispatch(AuthActions.getUserTokenAction("00" + employeeData.employeeId, userID[0]));
            dispatch(AuthActions.getNFAUserValidateTokenAction([employeeData.userPrincipalName, employeeData.mail]));
        } else if (state.employeeId !== "" && state.userId !== "") {
            trackEvent(appInsights, TraceEvent.TML_TASK_MODULE, { userId: state.userId, path: window && window.location.pathname });
            dispatch(AuthActions.getUserTokenAction(state.employeeId, state.userId as string));
        }
    }, [rootState.leaveData.employeeData && rootState.leaveData.employeeData.isSuccess, state.employeeId, state.userId]);


    // React.useEffect(() => {
    //     if (rootState.authData.userAuth.isSuccess && rootState.authData.userAuth.status_code === "200" && rootState.authData.userAuth.token && !rootState.serviceData.getServiceData.services_status) {
    //         dispatch(getServiceAvailabilityDataAction(rootState.authData.userAuth.token, EmpValueByCode[rootState.authData.userAuth.comp_code as keyof typeof EmpValueByCode]));
    //     }
    // }, [rootState.authData.userAuth.isSuccess]);

    // if ((!isLogin && !isError) || (rootState.authData.userAuth.isLoading || rootState.authData.userAuth.isLoading)) {
    //   return <Loader></Loader>;
    // } else if ((isError && !isTaskModule) || (rootState.authData.userAuth.isSuccess && rootState.authData.userAuth.status_code !== "200")) {
    //   return (
    //     <Provider theme={theme} lang="en-US">
    //       <AccessDenied />
    //     </Provider>
    //   );
    // }
    // else if ((rootState.authData.userAuth.isSuccess && rootState.authData.userAuth.status_code === "200")) {
    //   return (
    //     <Provider theme={theme} lang="en-US">
    //       <AppInsightsContext.Provider value={ai.reactPlugin}>
    //         <RouteList QueryData={state} />
    //       </AppInsightsContext.Provider>
    //     </Provider>
    //   )
    // }
    // else
    //   return <Loader></Loader>;

    return (
        <Provider theme={theme} lang="en-US">
            <AppInsightsContext.Provider value={ai.reactPlugin}>
                {rootState.authData.userAuth.isSuccess && rootState.authData.userAuth.status_code === "200" ?
                    <RouteList QueryData={state} /> :
                    (rootState.authData.userAuth.isSuccess && rootState.authData.userAuth.status_code !== "200" && rootState.authData.userAuth.istatus_code === "605") || isError ?
                        <AccessDenied /> :
                        rootState.authData.userAuth.isSuccess && rootState.authData.userAuth.status_code === "500" && rootState.authData.userAuth.istatus_code !== "605" ?
                            <AccessDenied /> :
                            rootState.authData.userAuth.isSuccess && rootState.authData.userAuth.status_code !== "200" && rootState.authData.userAuth.istatus_code !== "605" ?
                                // <ErrorComponent message={rootState.authData.userAuth.message} /> :
                                <RouteList QueryData={state} /> :
                                <LazyFallBack />
                }
            </AppInsightsContext.Provider>
            <Dialog open={idleTime.isActive} content={<Flex column gap="gap.medium" fill vAlign='center' hAlign="center" >
                <Text content="Your session expired" size='larger' weight='bold' color="brand" />
                <Text content="Please click the refresh button to continue" size='large' weight='semibold' />
                {idleTime.isError && <Text size='large' content={rootState.authData.userAuth.message} error weight="bold" />}
                <Button content={idleTime.isError ? "Reload" : "Refresh"} primary loading={idleTime.isLoading} disabled={idleTime.isLoading} onClick={() => idleTime.isError ? window.location.reload() : refreshToken()} />
            </Flex>} />
        </Provider>
    );
}

export default App;